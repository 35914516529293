import { useState, useEffect } from "react";
import styles from "./MassiveSign.module.scss";
import { Button } from "../../Button";
import { ModalWindow } from "../../ModalWindow";
import { ParticipantsList } from "./ParticipantsList/ParticipantsList";
import { CopyIcon } from "../../../assets/icons/CopyIcon";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REMOTE_TOKEN } from "../../../services/queries/mutations/massiveSigns/remoteToken";
import { CheckedIcon } from "../../../assets/icons/CheckedIcon";

export const MassiveSign = ({ participants }) => {
  const [isMassive, setIsMassive] = useState(false);
  const [currentParticipantUuid, setCurentParticipantUuid] = useState("");
  const navigate = useNavigate();
  const [getRemoteToken, { data }] = useMutation(REMOTE_TOKEN);
  const [remoteToken, setRemoteToken] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const copyText = async (text) => {
    if (!isCopied) {
      setIsCopied(true);
      await navigator.clipboard.writeText(text);
    }
  };

  useEffect(() => {
    if (currentParticipantUuid) {
      getRemoteToken({
        variables: {
          participantUuid: currentParticipantUuid,
        },
      });
    }
  }, [currentParticipantUuid]);

  useEffect(() => {
    if (data && data?.remoteSigningDocuments) {
      setRemoteToken(data?.remoteSigningDocuments?.token);
    }
  }, [data]);

  return (
    <div className={styles.isMassiveWrapper}>
      <Button variant="green" type="button" onClick={() => setIsMassive(true)}>
        Удаленное подписание
      </Button>
      <ModalWindow
        show={isMassive}
        setShowModal={() => setIsMassive(false)}
        dialogClassName={styles.massiveSignModal}
        size={"small"}
      >
        <ParticipantsList
          participants={participants}
          currentParticipantUuid={currentParticipantUuid}
          setCurentParticipantUuid={setCurentParticipantUuid}
          setIsCopied={setIsCopied}
        />
        <div className={styles.signedLinkWrapper}>
          {remoteToken !== "" && (
            <>
              <h3>Ссылка для подписания:</h3>
              <div>
                <span>
                  {`https://stage.unireg.redfoxlab.ru/massive-sign/${remoteToken}`.slice(
                    0,
                    70
                  ) + "..."}
                </span>
                <span
                  className={styles.copyLink}
                  onClick={() =>
                    copyText(
                      `https://stage.unireg.redfoxlab.ru/massive-sign/${remoteToken}`
                    )
                  }
                >
                  <abbr title={!isCopied ? 'Копировать ссылку' : 'Ссылка скопирована'}>
                    {!isCopied ? <CopyIcon /> : <CheckedIcon />}
                  </abbr>
                </span>
              </div>
            </>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

/* 

http://localhost:3000/applications/edit/QXBwbGljYXRpb25UeXBlOjg3
*/
