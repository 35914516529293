import { useNavigate, useParams } from "react-router-dom";
import { InfoList } from "../../../components/InfoList/ui/InfoList";
import { Sidebar } from "../../../components/Sidebar";
import styles from "./Cabinet.module.scss";
import { ToastComponent } from "../../../components/ToastComponent";
import { Logo } from "../../../assets/icons/Logo";
import { Button } from "../../../components/Button";
import { Preloader } from "../../../components/Preloader";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import {
  CLIENT_INFO,
  CLIENT_INFO_PROFILE,
} from "../../../services/queries/queries/client/clientInfo";
import { formatPhoneNumber } from "../../../helpers/formattedPhone";
import { useDispatch, useSelector } from "react-redux";
import {
  documentsData,
  documentsStatus,
} from "../../Contract/model/selectors/clientDocuments";
import { getClientDocuments } from "../../Contract/model/services/getClientDocuments";
import { ClientDocumentsList } from "../../../components/Lists/ui/ClientDocumentsList";
import { SidebarSelect } from "../../../components/SidebarSelect/ui/SidebarSelect";
import cn from "classnames";
import { EdsList } from "../../../components/Lists/ui/EdsList";
import { formattedDateFunc } from "../../../helpers/formattedDateFunc";
import { downloadExistFiles } from "../../../components/Forms/ui/ApplicationForm/ApplicationForm";

export const Cabinet = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [showProfile, setShowProfile] = useState(false);
  const [changeApplication, setChangeApplication] = useState("");
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const id = localStorage.getItem("id");
  const { register } = useForm();
  const dispatch = useDispatch();
  const isLoading = useSelector(documentsStatus);
  const [showContract, setShowContract] = useState(false);
  const [currentApplication, setCurrentApplication] = useState([]);
  const edsStstus = localStorage.getItem("edsStstus");
  const [showEds, setShowEds] = useState("false");
  const [socketData, setSocketData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [currentPayments, setCurrentPayments] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const [allRequests, setAllRequests] = useState([]);
  const [clientDocuments, setCliendDocuments] = useState([]);
  const loadingDocuments = useSelector(
    (state) => state.loadingDocuments.documentLoading
  );

  const { data } = useQuery(CLIENT_INFO, {
    variables: { id },
    skip: !id,
  });

  

  const { data: clientData, loading: clientLoading } = useQuery(
    CLIENT_INFO_PROFILE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [clientLazyInfo, { data: clientLazyData }] = useLazyQuery(
    CLIENT_INFO_PROFILE,
    {
      fetchPolicy: "no-cache",
    }
  );

  const toggleProfileHandler = () => {
    setShowProfile(!showProfile);
  };

  useEffect(() => {
    if (clientData?.physicalPersonProfile?.personalDocuments) {
      setSocketData(clientData?.physicalPersonProfile?.personalDocuments);
    }

    if (clientData?.physicalPersonProfile?.participants) {
      clientData?.physicalPersonProfile?.participants?.forEach(
        (participant) => {
          setAllApplications((prev) => [...prev, participant?.application]);
        }
      );
    }

    if (clientLazyData?.physicalPersonProfile?.personalDocuments) {
      setSocketData(clientLazyData?.physicalPersonProfile?.personalDocuments);
    }
  }, [clientData, clientLazyData]);

  useEffect(() => {
    if (currentApplication[0]?.application.payments?.length > 0) {
      currentApplication[0]?.application.payments?.map(item => {
        if(clientData?.physicalPersonProfile?.payments?.some(payment => payment.amount === item.amount)){
          setCurrentPayments(prev => [...prev, item]);
        }
      })
    } else {
      setCurrentPayments([])
    }

    if (
      currentApplication &&
      currentApplication[0]?.application?.requestHistory?.length > 0
    ) {
      setAllRequests(currentApplication[0]?.application?.requestHistory);
    }

    if (
      currentApplication &&
      currentApplication[0]?.documentSignatures.length > 0
    ) {
      setCliendDocuments([]);
      setCliendDocuments((prev) => [
        ...prev,
        ...currentApplication[0]?.documentSignatures,
      ]);
    }

    // if(currentApplication && currentApplication[0]?.application?.documents){
    //   setCliendDocuments([])
    //   setCliendDocuments(prev => [...currentApplication[0]?.application?.documents, ...prev])
    // }
  }, [currentApplication]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data) {
        console.log(data, 'websocket')
        if (data.type === "person_document_request") {
          clientLazyInfo();
          setSocketData((prev) => {
            return prev.map((item) => {
              if (item?.document?.uuid === data?.document?.uuid) {
                return {
                  ...item,
                  document: {
                    ...item.document,
                    title: data?.document?.title,
                  },
                };
              }
              return item;
            });
          });
        }

        if (
          data.type === "payment_request_updated" &&
          clientData?.physicalPersonProfile?.uuid === data.person_uuid
        ) {
          setCurrentPayments((prev) => [
            {
              amount: data.amount,
              paymentType: data.payment_type,
              payTillDate: data.pay_till_date,
              receiptUrl: data.receipt,
            },
            ...prev,
          ]);
        }

        if(data.type === 'request_updated_rosreestr'){
          console.log(data, 'websocket')
        }

        if(data.type === 'person_create_signature'){
          setCliendDocuments(prev => [...prev, {state: data.state, id: data.id, document: {title: data.document_title}}])
        }

        if(data.type === 'person_delete_signature'){
          const newDocuments = clientDocuments.filter(item => item?.document?.uuid !== data.document_uuid)
          setCliendDocuments(newDocuments)
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (data && data?.user?.physicalPerson) {
      dispatch(getClientDocuments(data?.user?.physicalPerson?.uuid));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (changeApplication !== "") {
      const application =
        clientData?.physicalPersonProfile?.participants?.filter(
          (participant) => changeApplication === participant.application.uuid
        );

      setCurrentApplication(application);
    } else {
      setCurrentApplication([
        clientData?.physicalPersonProfile?.participants[0],
      ]);
    }
  }, [changeApplication, clientData]);

  useEffect(() => {
    const edsStatus = localStorage.getItem("edsStatus");

    if (edsStatus) {
      setShowEds(edsStatus);
    }
  }, []);

  console.log(currentApplication, 'currentApplication')

  return (
    <div className={styles.cabinetWrapper}>

      <Sidebar className={styles.cabinetSidebar}>
        <div className={styles.logoBox}>

          <Logo />

        </div>
        <div className={styles.userInfo}>

          {!clientLoading ? (
            <span>
              Добро пожаловать, <br />
              <span>
                {clientData?.physicalPersonProfile?.lastName +
                  " " +
                  clientData?.physicalPersonProfile?.firstName +
                  " " +
                  clientData?.physicalPersonProfile?.patronymic}
              </span>
            </span>
          ) : (
            <Preloader variant="small" className={styles.clientPreloader} />
          )}

          <div
            className={styles.profileToastWrapper}
            ref={sidebarRef}
            onClick={toggleProfileHandler}
          >
            <Button variant="transparent">Профиль</Button>
            <ToastComponent
              isOpen={showProfile}
              setIsOpen={setShowProfile}
              toastRef={sidebarRef.current}
              className={styles.profileToast}
            >
              <div className={styles.sidebarProfile}>
                <div className={styles.toastLinks}>
                  <span className={styles.toastDescr}>Данные для входа</span>
                  <p className={styles.clientContacts}>
                    {clientData?.physicalPersonProfile?.user?.email}
                  </p>
                  <p className={styles.clientContacts}>
                    {formatPhoneNumber(
                      clientData?.physicalPersonProfile?.user?.phone
                    )}
                  </p>
                </div>
                <div className={styles.toastButtons}>
                  <Button variant="outline-green">Сменить пароль...</Button>

                  <Button
                    variant="transparent"
                    onClick={() => {
                      localStorage.removeItem("accessToken");
                      navigate("/login/");
                    }}
                    className={styles.exitBtn}
                  >
                    Выйти из профиля
                  </Button>

                </div>
              </div>
            </ToastComponent>
          </div>

          {clientData?.physicalPersonProfile?.participants?.length > 1 ? (
            <div className={styles.selectBox}>
              <span className={styles.selectTitle}>Ваши сделки:</span>
              <SidebarSelect
                clientData={clientData && clientData}
                setApplication={setChangeApplication}
              />
            </div>
          ) : <div>
              <span className={styles.selectTitle}>Ваши сделки: <br /></span>
              {currentApplication[0]?.application?.realObject?.address?.value}
            </div>}

        </div>

        {!clientData?.physicalPersonProfile?.snils ||
        clientData?.physicalPersonProfile?.personalDocuments?.length > 0 ? (
          <p className={styles.sidebarDescr}>
            Вам необходимо заполнить свои персональные данные и далее
            ознакомиться с договором
          </p>
        ) : null}

      </Sidebar>

      <div className={styles.cabinetContent}>
        <div>
          <h3 className={styles.contentTitle}>Личные документы</h3>
          <InfoList
            className={styles.contentList}
            onClick={() =>
              navigate(`/client-documents/`, {
                state: {
                  clientData: clientData,
                },
              })
            }
          >
            <h3>Удостоверение личности и СНИЛС</h3>
            {clientLoading ? (
              null
            ) : !clientData?.physicalPersonProfile?.snils ? (
              <span>Ждем данные</span>
            ) : null}
          </InfoList>
        </div>

        {!clientLoading ? (
          socketData?.length > 0 && (
            <div>
              <h3 className={styles.contentTitle}>
                Документы, ожидающие действий
              </h3>
              {socketData?.map((personalDocument, index) => (
                <InfoList
                  key={index}
                  className={styles.contentList}
                  onClick={() =>
                    navigate("/hot-document/", {
                      state: {
                        action: personalDocument.actyonType,
                        documentTitle: personalDocument?.document?.title,
                        documentUuid: personalDocument.uuid,
                        clientUuid: clientData?.physicalPersonProfile?.uuid,
                        confirmFile: personalDocument?.document?.presignedUrl,
                        showEds: showEds,
                      },
                    })
                  }
                >
                  <h3>{personalDocument?.document?.title}</h3>
                  <span>
                    {personalDocument.actyonType === "REQUEST_DOCUMENT"
                      ? "Документ запрошен"
                      : personalDocument.actyonType === "CONFIRM_DOCUMENT" &&
                        "Документ ожидает подтверждения"}
                  </span>
                </InfoList>
              ))}
            </div>
          )
        ) : (
          <Preloader />
        )}


        {
        clientData?.physicalPersonProfile?.signaturesCurrent?.length > 0 && clientData?.physicalPersonProfile?.snils ? (
          <div>
            <h3 className={styles.contentTitle}>Электронная подпись</h3>
            <EdsList
              data={clientData?.physicalPersonProfile}
              className={styles.edsList}
            />
          </div>
        ) : null}


        {clientData?.physicalPersonProfile?.participants?.length > 0 ? (
          <div>
            <h3 className={styles.contentTitle}>Объект</h3>
            <InfoList
              className={styles.objectList}
              onClick={() =>
                navigate("/object-info/", {
                  state: { objectInfo: currentApplication[0]?.application },
                })
              }
            >
              {currentApplication[0]?.application?.realObject?.address?.value ||
                "Адрес не указан"}
            </InfoList>
          </div>
        ) : null}


        {clientData?.physicalPersonProfile?.participants?.length > 0 ? (
          <div>
            <h3 className={styles.contentTitle}>Документы по сделке</h3>
            <ClientDocumentsList
              clientData={clientDocuments}
              className={styles.dealList}
            />
          </div>
        ) : null}


        {currentApplication[0]?.application?.requestHistory.length > 0 &&
          allRequests.length > 0 && (
            <div>
              <h3 className={styles.contentTitle}>Росреестр</h3>

              <InfoList className={styles.contentList}>
                <h3
                  className={styles.requestTitle}
                  onClick={() =>
                    navigate("/reestr-history/", {
                      state: {
                        historyData: allRequests,
                        downloadZip: currentApplication[0]?.downloadZip,
                        requestPackage: currentApplication[0]?.requestPackage,
                        kuvd: currentApplication[0]?.application?.kuvd
                      },
                    })
                  }
                >
                  Росреестр
                </h3>
              </InfoList>
            </div>
          )}


        {currentPayments?.length > 0 && (
          <div>
            <h3 className={styles.contentTitle}>Пошлины</h3>
            {currentPayments.map((payment, index) => (
              <InfoList
                key={index}
                className={styles.dutyList}
                onClick={() =>
                  navigate("/duty/", {
                    state: {
                      fileUrl: payment?.receiptUrl,
                    },
                  })
                }
              >
                <span className={styles.dutyPrice}>{payment?.amount} ₽</span>
                <p className={styles.dutyInfo}>
                  {payment?.paymentType === "PAYMENT_REGISTRATION"
                    ? "Оплата госпошлины за государственную регистрацию прав и сделок в отношении объектов недвижимости"
                    : "Оплата выписки"}
                </p>
                <span className={styles.paymentDate}>
                  Оплатить до: {formattedDateFunc(payment?.payTillDate)}
                </span>
              </InfoList>
            ))}
          </div>
        )}

      </div>
    </div>
  );
};
