export const getHashFromXml = async(xmlFile) => {
  try {
    const parser = new DOMParser()
    const xml = parser.parseFromString(xmlFile, "application/xml")
    const xmlSignature = xml.getElementsByTagName("ПодпИзобр")[0].textContent

    await window?.cadesplugin;
    const signedData = await window?.cadesplugin?.CreateObjectAsync("CAdESCOM.CadesSignedData");

    //Загружаем полученную из xml подпись
    await signedData?.VerifyCades(xmlSignature, window?.cadesplugin?.CADESCOM_CADES_BES);

     // Получаем список подписантов
     const signers = await signedData?.Signers;
     const signerCount = await signers?.Count;
 
     if (signerCount === 0) {
       throw new Error("Подписанты не найдены в подписи");
     }

     //извлекаем сертифкат подписанта
     const signer = await signers?.Item(1);
     const certificate = await signer?.Certificate;

     //получаем хэш сертификата
     const signerHash = await certificate?.Thumbprint;

     return signerHash

  } catch (error) {
    console.log(error)
  }
}