import { FormSelect } from "react-bootstrap";
import { useForm } from "react-hook-form";
import styles from "./SidebarSelect.module.scss";
import { useState, useCallback, useEffect } from "react";
import { Select } from "../../Select/ui/Select";

export const SidebarSelect = ({ clientData, setApplication }) => {
  const { register, watch } = useForm();
  const defaultValue = JSON.parse(sessionStorage.getItem("defaultValue"));
  const [options, setOptions] = useState(defaultValue ? [defaultValue] : []);

  const changeVariant = watch("clientApplications");

  useEffect(() => {
    setOptions([]);

    if (clientData?.physicalPersonProfile?.participants) {
      clientData?.physicalPersonProfile?.participants?.forEach(
        (participant) => {
          if (
            defaultValue &&
            participant?.application?.uuid !== defaultValue?.value
          ) {
            sessionStorage.removeItem("defaultValue");
          }

          setOptions((options) => {
            const newOption = {
              value: String(participant?.application?.uuid) || '',
              title: String(participant?.application?.realObject?.address?.value) || '',
            };

            if (newOption.title !== 'undefined' && !options?.some((option) => option?.value === newOption?.value)) {
              return [...options, newOption];
            }
            return options;
          });
        }
      );
    }
  }, [clientData]);

  useEffect(() => {
    if (changeVariant) {
      setApplication(changeVariant);
    }
  }, [changeVariant]);

  useEffect(() => {
    if (changeVariant) {
      options.forEach((option) => {
        if (option?.value === changeVariant) {
          sessionStorage.setItem("defaultValue", JSON.stringify(option));
        }
      });
    }
  }, [options, changeVariant]);

  return (
    <Select
      isFormSelect={true}
      name="clientApplications"
      variant={"bordered"}
      options={options}
      register={register}
      className={styles.sidebarSelect}
    />
  );
};
