import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Applications } from "../../../pages/Applications";
import { Egrn } from "../../../pages/Egrn";
import { Login } from "../../../pages/Login";
import { Objects } from "../../../pages/Objects";
import { Support } from "../../../pages/Support";
import { Users } from "../../../pages/Users";
import { Bases } from "../../../pages/Bases/ui/Bases";
import { NewApplication } from "../../../pages/NewApplication";
import PasswordRecovery from "../../../pages/passwordRecovery/passwordRecovery";
import {EditApplication} from "../../../pages/EditApplication/ui/EditApplication";
import {Cabinet} from "../../../pages/Cabinet";
import {ClientDocuments} from "../../../pages/ClientDocuments";
import {useMemo} from "react";
import {Contract} from "../../../pages/Contract/index";
import {EdsInfo} from "../../../pages/EdsInfo";
import {Duty} from "../../../pages/Duty";
import {ApplicationDocuments} from "../../../pages/ApplicationDocuments";
import {ClientObject} from "../../../pages/ClientObject/ui/ClientObject";
import {HotDocument} from "../../../pages/HotDocument/ui/HotDocument";
import {AdditionalApp} from "../../../pages/AdditionalApp";
import {ReestrHistory} from "../../../pages/ReestrHistory/ui/ReestrHistory";
import { CopyApplication } from "../../../pages/CopyApplication";
import { MassiveSignPage } from "../../../pages/MassiveSignPage/ui/MassiveSignPage";

const client = [
  {
    id: 1,
    path: "/client-cabinet/",
    element: <Cabinet />,
  },

  {
    id: 2,
    path: "*",
    element: <Navigate to="/client-cabinet/" />,
  },

  {
    id: 3,
    path: "/client-documents/",
    element: <ClientDocuments />,
  },

  {
    id: 4,
    path: "/login",
    element: <Login />,
  },
  {
    id: 5,
    path: "/register/*",
    element: <PasswordRecovery />,
  },

  {
    id: 6,
    path: "/contract/",
    element: <Contract />,
  },

  {
    id: 7,
    path: "/eds-info/",
    element: <EdsInfo />,
  },

  {
    id: 8,
    path: "/duty/",
    element: <Duty />,
  },

  {
    id: 9,
    path: "/application-documents/:id",
    element: <ApplicationDocuments />,
  },

  

  {
    id: 10,
    path: "/hot-document/",
    element: <HotDocument />,
  },

  {
    id: 11,
    path: "/reestr-history/",
    element: <ReestrHistory />,
  },

  {
    id: 12,
    path: "/reset-password/*",
    element: <PasswordRecovery />,
  },

  {
    id: 13,
    path: "/object-info/",
    element: <ClientObject />,
  },

  // {
  //   id: 14,
  //   path: '/massive-sign/:token',
  //   element: <MassiveSignPage/>
  // }
];

const manager = [
  {
    id: 1,
    path: "/applications/",
    element: <Applications />,
  },

  {
    id: "a" + 1,
    path: "/applications/new",
    element: <NewApplication />,
  },

  {
    id: 2,
    path: "/egrn/",
    element: <Egrn />,
  },

  {
    id: 3,
    path: "/login",
    element: <Login />,
  },

  {
    id: 4,
    path: "/objects/",
    element: <Objects />,
  },

  {
    id: 5,
    path: "/support/",
    element: <Support />,
  },

  {
    id: 6,
    path: "/users/",
    element: <Users />,
  },

  {
    id: 7,
    path: "/bases/",
    element: <Bases />,
  },

  {
    id: 8,
    path: "/reset-password/*",
    element: <PasswordRecovery />,
  },

  {
    id: 9,
    path: "/register/*",
    element: <PasswordRecovery />,
  },

  {
    id: 10,
    path: "/applications/edit/:id",
    element: <EditApplication />,
  },

  {
    id: 11,
    path: "/",
    element: <Applications />,
  },

  {
    id: 12,
    path: "/additional/:id",
    element: <AdditionalApp />,
  },

  {
    id: 13,
    path: "/additional/edit/:id",
    element: <AdditionalApp />,
  },

  {
    id: 14,
    path: "/copy-application/:id",
    element: <CopyApplication />,
  },

  {
    id: 15,
    path: '/massive-sign/:token',
    element: <MassiveSignPage/>
  }
];

const admin = [
  {
    id: 1,
    path: "/applications/",
    element: <Applications />,
  },

  {
    id: "a" + 1,
    path: "/applications/new",
    element: <NewApplication />,
  },

  {
    id: 2,
    path: "/egrn/",
    element: <Egrn />,
  },

  {
    id: 3,
    path: "/login",
    element: <Login />,
  },

  {
    id: 4,
    path: "/objects/",
    element: <Objects />,
  },

  {
    id: 5,
    path: "/support/",
    element: <Support />,
  },

  {
    id: 6,
    path: "/users/",
    element: <Users />,
  },

  {
    id: 7,
    path: "/bases/",
    element: <Bases />,
  },

  {
    id: 8,
    path: "/",
    element: <Applications />,
  },
  {
    id: 9,
    path: "/register/*",
    element: <PasswordRecovery />,
  },

  {
    id: 10,
    path: "/applications/edit/:id",
    element: <EditApplication />,
  },

  {
    id: 11,
    path: "/additional/:id",
    element: <AdditionalApp />,
  },

  {
    id: 12,
    path: "/additional/edit/:id",
    element: <AdditionalApp />,
  },

  {
    id: 13,
    path: "/reset-password/*",
    element: <PasswordRecovery />,
  },
  
  {
    id: 14,
    path: "/copy-application/:id",
    element: <CopyApplication />,
  },

  {
    id: 15,
    path: '/massive-sign/:token',
    element: <MassiveSignPage/>
  }
];

const roleNull = [
  {
    id: 1,
    path: "/login",
    element: <Login />,
  },
  {
    id: 2,
    path: "/register/*",
    element: <PasswordRecovery />,
  },
  {
    id: 3,
    path: "/reset-password/*",
    element: <PasswordRecovery />,
  },
  {
    id: 4,
    path: "*",
    element: <Login />,
  },

  {
    id: 5,
    path: '/massive-sign/:token',
    element: <MassiveSignPage/>
  }
];

const ProtectedRoute = ({ children, role, ...rest }) => {
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  const currentPath = location.pathname;

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    const payloadBase64 = token.split(".")[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));
    const exp = decodedPayload.exp;

    if (!exp) {
      return true;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return exp < currentTime;
  };
  const isAuthenticated = token && !isTokenExpired(token);

  if (!isAuthenticated && currentPath !== "/login") {
    if (currentPath.startsWith("/reset-password")) {
      return children;
    } else if (currentPath.startsWith("/register")) {
      return children;
    } else if(currentPath.includes('/massive-sign/')) {
      return children;
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  return children;
};



export default ProtectedRoute;

export const AppRouter = () => {
  const role = localStorage.getItem("role");

  const routeVariantRender = useMemo(() => {
    if (role === "AD") {
      return (
        <>
          {admin.map((path) => (
            <Route
              key={path.id}
              path={path.path}
              element={<ProtectedRoute children={path.element} />}
            />
          ))}
        </>
      );
    }

    if (role === "MA") {
      return (
        <>
          {manager.map((path) => (
            <Route
              key={path.id}
              path={path.path}
              element={<ProtectedRoute children={path.element} />}
            />
          ))}
        </>
      );
    }

    if (role === "CL") {
      return (
        <>
          {client.map((path) => (
            <Route
              key={path.id}
              path={path.path}
              element={<ProtectedRoute children={path.element} />}
            />
          ))}
        </>
      );
    }

    if (role === null) {
      return (
        <>
          {roleNull.map((path) => (
            <Route
              key={path.id}
              path={path.path}
              element={<ProtectedRoute children={path.element} />}
            />
          ))}
        </>
      );
    }
  }, [role]);

  return (
    <Routes>
      {/* <Route path="/" element={<Navigate to="/applications/" />} />
      <Route path="/client-cabinet/" element={<Cabinet />}/>
      <Route path="/client-documents/" element={<ClientDocuments />}/>
      {role === "AD"
        ? admin
        : manager.map((path) => (
            <Route key={path.id} path={path.path} element={<ProtectedRoute children={path.element} role={role} userRole={role}/>}/>
          ))
        } */}
      {routeVariantRender}
    </Routes>
  );
};

/*




<ProtectedRoute
  path="/admin"
  role="admin"
  userRole={currentUser.role}
  component={AdminPage}
/>


*/
