import { useState, useContext, useEffect, memo } from "react";
import { Button } from "../../../components/Button";
import styles from "./Applications.module.scss";
import { InfoList } from "../../../components/InfoList/ui/InfoList";
import { Pagination } from "../../../components/Pagination/ui/Pagination";
import { Calendar } from "../../../components/Calendar";
import {
  ApplicationsSearch,
  ApplicationsTypeFilter,
  ApplicationsStatusFilter,
  ApplicationsSellerFilter,
} from "../../../components/Filters";
import { useApplicationsFilters } from "../../../hooks/useFilters";
import cn from "classnames";
import { ApplicationForm } from "../../../components/Forms";
import { Context } from "../../../App";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ALL_APPLICATIONS } from "../../../services/queries/queries/allApplications";
import { formattedDateFunc } from "../../../helpers/formattedDateFunc";
import { Link, useNavigate } from "react-router-dom";
import { ApplicationsFilters } from "../../../components/Filters/ui/ApplicationsFilters/ApplicationsFilters";
import { Preloader } from "../../../components/Preloader";
import { toast } from "react-toastify";
import { DocumentsLoader } from "../../../components/DocumentsLoader/ui/DocumentsLoader";
import { MAKE_PACKAGE_APPLICATION } from "../../../services/queries/mutations/applications/makePackage";
import { FILTERED_APPLICATIONS } from "../../../services/queries/queries/applications/filteredApplications";

export const Applications = () => {
  const [page, setPage] = useState(1);
  const { showModal } = useContext(Context);
  const totalResults = 10;
  const indexOfLastPage = page * totalResults;
  const indexOfFirstPage = indexOfLastPage - totalResults;
  const [status, setStatus] = useState("");
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [type, setType] = useState("");
  const [sellerType, setSellerType] = useState("");
  const [firstDate, setFirstDate] = useState("");
  const [secondDate, setSecondDate] = useState("");
  const [calendarField, setCalendarField] = useState("");
  const [isClear, setIsClear] = useState(false);
  const [dealParty, setDealParty] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  // const [error, setError] = useState(false);
  const [totalCountFiltered, setTotalCountedFiltered] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageFilter, setCurrentPageFilter] = useState(0);
  const [totalPageFilter, setTotalPageFilter] = useState(null);
  const first = 10; // Количество объектов на странице
  const offset = currentPage * first;
  const currentDate = sessionStorage.getItem("currentDate");

  const [makeDocumentsReq] = useMutation(MAKE_PACKAGE_APPLICATION)

  const shouldFetchAllApplications =
    !value &&
    !type &&
    !firstDate &&
    !secondDate &&
    !dealParty &&
    !status &&
    !searchValue &&
    !calendarField;

  const { data, loading } = useQuery(ALL_APPLICATIONS, {
    skip: !shouldFetchAllApplications,
    variables: {
      offset: offset,
      first: first,
    },
    fetchPolicy: "no-cache",
  });

  const totalObjects = data ? data.allApplications.totalCount : 0;
  const totalPages = Math.ceil(totalObjects / first);

  const [allApplications, setAllApplications] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const clearFilters = () => {
    setStatus("");
    setValue("");
    setSearchValue("");
    setType("");
    setCalendarField("");
    setSellerType("");
    setPage(1);
    setIsClear(true);
    setFirstDate("");
    setSecondDate("");
    setDealParty("");
    sessionStorage.clear();
    setCurrentPageFilter(0);
    setCurrentPage(0);
  };

  const applicationStatusvariantRender = (status) => {
    switch (status) {
      case "DRAFT":
        return "Черновик";
      case "AWAITING_SIGNATURE":
        return "Ожидает подписания";
      case "AWAITING_SUBMISSION":
        return "Ожидает отправки в Росреестр";
      case "SEND_TO":
        return "Отправлено в Росреестр";
      case "SENTTOPROCESSING":
        return "Отправлено в ПКУРП";
      case "QUITTANCESCREATED":
        return "Сформирована квитанция";
      case "SUSPENDED":
        return "Приостановлена";
      case "SEND_ERROR":
        return "Ошибка при отправке";
      case "REJECTED":
        return "Завершена отказом";
      case "RETURNED":
        return "Возвращена без расмотрения";
      case "PROCESSED":
        return "Обработка завершена";
      case "VALIDATIONERROR":
        return "Проверка не пройдена";
      case "VALIDATION":
        return "На проверке ФЛК";
      case "ACCEPTED":
        return "Принято от заявителя";
      case "PENDING":
        return "В ожидании";
      case "SEND_REQUEST":
        return "Запрос отправлен";
      case "TIMEOUTED":
        return "Истечение срока, предоставленного для оплаты";
      case "INTERRUPTED":
        return "Прекращено по инициативе заявителя";
      case "ERROR":
        return "Ошибка при обработке запроса";
      case "GENERATE_PACKAGE":
        return "Пакет сформирован";
      case "NOINFORMATION":
        return "Сведения отсутствуют";
    }
  };

  useEffect(() => {
    if (data && data?.allApplications?.edges) {
      setAllApplications(data?.allApplications?.edges);
      if (data?.allApplications?.edges.length <= 0) {
        toast.error("Записи не найдены");
      }
    }
  }, [data]);

  useEffect(() => {
    setLoadingStatus(loading);
  }, [loading]);

  useEffect(() => {
    setCurrentPage(parseInt(localStorage.getItem("page")));
  }, []);

  useEffect(() => {
    localStorage.setItem("page", currentPage);
  }, [currentPage]);

  return (
    <>
      <div className={styles.applicationsInner}>
        <h2 className={styles.applicationsTitle}>Актуальные заявки</h2>
        <div className={styles.applicationsFilters}>
          <ApplicationsFilters
            value={searchValue}
            typeSearch={type}
            status={status}
            firstDate={firstDate}
            mainValue={calendarField}
            secondDate={secondDate}
            dealParty={dealParty}
            allApplications={allApplications}
            setAllApplications={setAllApplications}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            isClear={isClear}
            setLoadingStatus={setLoadingStatus}
            setCurrentPageFilter={setCurrentPageFilter}
            setTotalPageFilter={setTotalPageFilter}
            currentPageFilter={currentPageFilter}
            setTotalCountedFiltered={setTotalCountedFiltered}
            shouldFetchAllApplications={shouldFetchAllApplications}
          />
          <ApplicationsSearch
            value={searchValue}
            setValue={setSearchValue}
            setCurrentPageFilter={setCurrentPageFilter}
            setCurrentPage={setCurrentPage}
          />
          <ApplicationsSellerFilter
            isClear={isClear}
            setIsClear={setIsClear}
            setDealParty={setDealParty}
            setCurrentPageFilter={setCurrentPageFilter}
            setCurrentPage={setCurrentPage}
          />
          <ApplicationsTypeFilter
            type={type}
            setType={setType}
            value={value}
            allApplications={allApplications}
            setAllApplications={setAllApplications}
            filteredTypeData={filteredData}
            setFilteredData={setFilteredData}
            setCurrentPageFilter={setCurrentPageFilter}
            setCurrentPage={setCurrentPage}
          />
          <ApplicationsStatusFilter
            status={status}
            setStatus={setStatus}
            isClear={isClear}
            setCurrentPageFilter={setCurrentPageFilter}
            setCurrentPage={setCurrentPage}
          />
          <Calendar
            firstDate={firstDate}
            setFirstDate={setFirstDate}
            secondDate={secondDate}
            setSecondDate={setSecondDate}
            mainValue={calendarField}
            setMainValue={setCalendarField}
            allApplications={allApplications}
            setAllApplications={setAllApplications}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            isClear={isClear}
            setIsClear={setIsClear}
            setCurrentPageFilter={setCurrentPageFilter}
            setCurrentPage={setCurrentPage}
          />
          <Button
            variant="transparent"
            className={styles.clearFilters}
            onClick={clearFilters}
          >
            Очистить
          </Button>
        </div>
        <div className={styles.applicationsInfo}>
          {!loadingStatus ? (
            <ul className={styles.applicationsList}>
              {allApplications?.length > 0 ? (
                allApplications
                  .slice(indexOfFirstPage, indexOfLastPage)
                  .map((item, index) => (
                    <InfoList
                      key={index}
                      className={styles.infoList}
                      onClick={() =>
                        item?.node?.type?.title ===
                        "Заявление о прекращении государственной регистрации" ||
                        item?.node?.type?.title ===
                          "Заявление об исправление технической ошибки" ||
                        item?.node?.type?.title ===
                          "Приостановление государственной регистрации" ||
                        item?.node?.type?.title ===
                          "Возобновление государственной регистрации" ||
                        item?.node?.type?.title ===
                          "Предоставление дополнительных документов"
                          ? navigate(`/additional/edit/${item.node.id}`)
                          : item.node.isCopy
                          ? navigate(`/copy-application/${item.node.id}`)
                          : navigate(`/applications/edit/${item.node.id}`)
                      }
                    >
                      <div className={styles.infoTop}>
                        <span className={styles.type}>
                          {item?.node?.type?.title}
                        </span>
                        <span className={styles.cuvd}>
                          <span>КУВД</span>
                          <div className={styles.cuvdInfo}>
                            <span className={styles.cuvd}>
                              {item?.node?.kuvd
                                ? item?.node?.kuvd
                                : "Не присвоен"}
                            </span>
                            &nbsp;
                            {/* <span>
                            {formattedDateFunc(item.node.createdAt, true)}
                          </span> */}
                          </div>
                        </span>
                        <span className={styles.status}>
                          <span className={styles.statusInfo}>Статус</span>{" "}
                          <span
                            className={cn({
                              [styles.statusLabel]:
                                item?.node?.status === "PROCESSED",
                            })}
                          >
                            {applicationStatusvariantRender(item?.node?.status)}
                            {/* {item?.node?.status === "DRAFT" ? (
                              <Link to={item?.node?.downloadZip}>
                                скачать пакет
                              </Link>
                            ) : null} */}
                            {item?.node?.status === "PROCESSED" && ' - '}
                            <DocumentsLoader 
                              downloadText='скачать пакет'
                              loadingText='загрузить пакет'
                              status={item?.node?.status}
                              downloadLink={item?.node?.downloadZip}
                              requestPackage={item?.node?.requestPackage}
                              uuid={item?.node?.uuid}
                              loadingRequest={makeDocumentsReq}
                              type='make_rosreestr_package'
                            />
                          </span>
                        </span>
                        <span className={styles.update}>
                          <span>Дата создания</span>{" "}
                          {formattedDateFunc(item?.node?.createdAt)}
                        </span>
                      </div>
                      {searchValue !== "" ? (
                        <div className={styles.infoBottom}>
                          {searchValue ===
                          item?.node?.realObject?.cadastralNumber ? (
                            <span className={styles.valueInfo}>
                              Кадастровый номер:{" "}
                              <span>
                                {item?.node?.realObject?.cadastralNumber}
                              </span>
                            </span>
                          ) : item?.node?.participants?.some(
                              (participant) =>
                                participant?.physicalPerson?.lastName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                participant?.physicalPerson?.firstName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                participant?.physicalPerson?.patronymic
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase())
                            ) ? (
                            item?.node?.participants?.map(
                              (participant, index) =>
                                (participant?.physicalPerson?.lastName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                  participant?.physicalPerson?.firstName
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                  participant?.physicalPerson?.patronymic
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())) && (
                                  <span
                                    key={index}
                                    className={styles.valueInfo}
                                  >
                                    <span>
                                      {participant?.role?.name + ":" + " "}
                                    </span>
                                    <span>
                                      {participant?.physicalPerson?.lastName +
                                        " " +
                                        participant?.physicalPerson?.firstName +
                                        " " +
                                        participant?.physicalPerson?.patronymic}
                                    </span>
                                  </span>
                                )
                            )
                          ) : item?.node?.participants?.some((participant) =>
                              participant?.legalPerson?.fullName
                                ?.toLowerCase()
                                .includes(searchValue.toLowerCase())
                            ) ? (
                            item?.node?.participants?.map(
                              (participant, index) =>
                                participant?.legalPerson?.fullName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) && (
                                  <span
                                    key={index}
                                    className={styles.valueInfo}
                                  >
                                    <span>
                                      {participant?.role?.name + ":" + " "}
                                    </span>
                                    <span>
                                      {participant?.legalPerson?.fullName}
                                    </span>
                                  </span>
                                )
                            )
                          ) : item?.node?.realObject?.address?.value
                              ?.toLowerCase()
                              .includes(searchValue.toLowerCase()) ? (
                            <span className={styles.valueInfo}>
                              <span>
                                Адрес: {item?.node?.realObject?.address?.value}
                              </span>
                            </span>
                          ) : item?.node?.participants?.some((participant) =>
                              participant?.legalPerson?.inn?.includes(
                                searchValue
                              )
                            ) ? (
                            item?.node?.participants?.map(
                              (participant, index) =>
                                participant?.legalPerson?.inn?.includes(
                                  searchValue
                                ) && (
                                  <span
                                    key={index}
                                    className={styles.valueInfo}
                                  >
                                    <span>
                                      ИНН юрлица:{" "}
                                      {participant?.legalPerson?.inn}
                                    </span>
                                  </span>
                                )
                            )
                          ) : (
                            item?.node?.participants?.some(
                              (participant) =>
                                participant?.representative &&
                                (participant?.representative?.lastName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                  participant?.representative?.firstName
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                  participant?.representative?.patronymic
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()))
                            ) &&
                            item?.node?.participants?.map(
                              (participant, index) =>
                                participant?.representative &&
                                (participant?.representative?.lastName
                                  ?.toLowerCase()
                                  .includes(searchValue.toLowerCase()) ||
                                  participant?.representative?.firstName
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                  participant?.representative?.patronymic
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())) ? (
                                  <span
                                    key={index}
                                    className={styles.valueInfo}
                                  >
                                    <span>
                                      Представитель по сделке:{" "}
                                      {participant?.representative?.lastName +
                                        " " +
                                        participant?.representative?.firstName +
                                        " " +
                                        participant?.representative?.patronymic}
                                    </span>
                                  </span>
                                ) : null
                            )
                          )}
                        </div>
                      ) : null}
                    </InfoList>
                  ))
              ) : (
                <></>
                // <Error error={error} errorText="Заявки не найдены" />
              )}
              {data ? (
                <Pagination
                  page={currentPage}
                  setPage={setCurrentPage}
                  totalPages={[...Array(totalPages).keys()]}
                />
              ) : (
                <Pagination
                  page={currentPageFilter}
                  setPage={setCurrentPageFilter}
                  totalPages={[...Array(totalPageFilter).keys()]}
                />
              )}

              {allApplications?.length > 0 ? (
                <>
                  <p className={styles.paginationInfo}>
                    Запись {offset + 1 || currentPageFilter + 1} -{" "}
                    {data?.allApplications?.totalCount > totalCountFiltered
                      ? data?.allApplications?.totalCount
                      : totalCountFiltered < offset + 10
                      ? totalCountFiltered || data?.allApplications?.totalCount
                      : offset + 10}{" "}
                    из {data?.allApplications?.totalCount || totalCountFiltered}{" "}
                  </p>
                </>
              ) : (
                <p className={styles.paginationInfo}>Больше записей нет</p>
              )}
            </ul>
          ) : (
            <Preloader />
          )}
        </div>
      </div>

      {showModal && <ApplicationForm />}

      {/* <ModalWindow
        show={true}
        // setShowModal={setActiveItemId}
        size="m"
      >
       <SignToken />
       <ChooseSigners />
       <ReqestDocument />
       <DeleteDeal />
      </ModalWindow> */}
    </>
  );
};
