import styles from "./ParticipantsList.module.scss";

export const ParticipantsList = ({
  participants,
  currentParticipantUuid,
  setCurentParticipantUuid,
  setIsCopied,
}) => {
  return (
    <div>
      <h3 className={styles.participantsListTitle}>Выберите участника</h3>
      <ul className={styles.participantsList}>
        {participants?.map((item, index) => (
          <>
            {item?.physicalPerson && (
              <li
                key={item?.id}
                className={
                  item?.uuid === currentParticipantUuid
                    ? styles.activeParticipant
                    : ""
                }
                onClick={() => {
                  setCurentParticipantUuid(item?.uuid);

                  if (setIsCopied) {
                    setIsCopied(false);
                  }
                }}
              >
                {!item?.representative
                  ? item?.physicalPerson?.lastName +
                    " " +
                    item?.physicalPerson?.firstName +
                    " " +
                    item?.physicalPerson?.patronymic
                  : item?.representative?.lastName +
                    " " +
                    item?.representative?.firstName +
                    " " +
                    item?.representative?.patronymic}
              </li>
            )}
            {item?.representative && item?.legalPerson && (
              <li
                key={item?.id}
                className={
                  item?.uuid === currentParticipantUuid
                    ? styles.activeParticipant
                    : ""
                }
                onClick={() => {
                  setCurentParticipantUuid(item?.uuid);
                  if (setIsCopied) {
                    setIsCopied(false);
                  }
                }}
              >
                {item?.representative?.lastName +
                  " " +
                  item?.representative?.firstName +
                  " " +
                  item?.representative?.patronymic}
              </li>
            )}
          </>
        ))}
      </ul>
    </div>
  );
};
