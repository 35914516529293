import { gql } from "@apollo/client";

export const CLIENT_INFO = gql`
  query ClientInfo($id: ID!) {
    user(id: $id) {
      phone
      email
      physicalPerson {
        uuid
        id
        firstName
        lastName
        patronymic
        gender
        dateOfBirth
        placeOfBirth
        snils
        inn
        createdAt
        citizenship
        participants {
          application {
            documents {
              title
            }
          }
        }
        signatureRelease {
          isActive
          connector
          state
          type
        }
        registrationAddress {
          value
          realobject {
            address {
              value
            }
          }
        }
        document {
          type
          code
          dateIssue
          number
          series
          issuedBy
        }
      }
    }
  }
`;

export const CLIENT_INFO_PROFILE = gql`
  query MyQuery {
    physicalPersonProfile {
      createdAt
      citizenship
      dateOfBirth
      inn
      id
      snils
      firstName
      dateOfBirth
      placeOfBirth
      gender
      lastName
      patronymic
      uuid
      registrationAddress {
        value
        kladr
        fias
        regionKladrId
        region
        locality
        street
      }
      document {
        type
        series
        number
        dateIssue
        issuedBy
        code
      }
      user {
        id
        uuid
        email
        phone
        physicalPerson {
          uuid
        }
      }
      signaturesCurrent {
        consumerUuid
        connector
        isActive
      }
      personalDocuments {
        uuid
        actyonType
        document {
          uuid
          title
          presignedUrl
          documentType {
            name
          }
        }
      }
      participants {
        uuid
        id
        application {
          uuid
          area
          statusName
          downloadZip
          requestPackage
          ownership {
            title
          }
          createdAt
          requestSend
          id
          kuvd
          mortgage
          realObject {
            address {
              value
            }
            type {
              title
            }
            developer
            cadastralNumber
          }
          status
          sum
          documents {
            title
            file
            presignedUrl
            sigFile
          }

          requestHistory {
            id
            statusName
            statusTime
          }

          payments {
            id
            uuid
            amount
            paymentType
            payTillDate
            receiptUrl
          }
        }
        documentSignatures {
          state
          document {
            uuid
            title
            presignedUrl
            pdfUrl
            presignedSigUrl
            sigFile
          }
        }


        physicalPerson{
          personDocuments{
            actyonType
          }
        }
      }
      payments {
        id
        uuid
        amount
        paymentType
        payTillDate
        receiptUrl
      }
    }
  }
`;
