import { gql } from "@apollo/client";

export const UPDATE_APPLICATION = gql`
	mutation UpdateApplication(
		$applicationData: UpdateApplicationInput!
		$applicationUuid: UUID!
	) {
		updateApplication(
			applicationData: $applicationData
			applicationUuid: $applicationUuid
		) {
			application {
				sum
				area
        statementRegDate
				ownership {
					title
				}
				mortgage
				# realObject {
				# 	cadastralNumber
				# 	developer
				# 	buildingArea
				# }
				documents {
					id
					uuid
					title
					documentsSignatures {
					id
					state
					person {
						firstName
						lastName
						patronymic
					}
					}
					documentType {
					id
					uuid
					name
					code
					}
					file
					sigFile
					presignedUrl
					presignedSigUrl
				}
				participants {
					id
					uuid
				}
			}
		}
	}
`;
