import { useLocation, useNavigate } from "react-router-dom";
import styles from "./HotDocument.module.scss";
import { useState, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PERSONAL_DOCUMENTS } from "../../../services/queries/mutations/client/updatePersonalDocuments";
import { CLIENT_INFO_PROFILE } from "../../../services/queries/queries/client/clientInfo";
import { FileField } from "../../../components/FileField/ui/FileField";
import { useForm } from "react-hook-form";
import { Button } from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { Preloader } from "../../../components/Preloader";
import { DocumentViewer } from "../../../components/DocumentViewer/ui/DocumentViewer";
import { ContractComment } from "../../../components/ContractComment/ui/ContractComment";
import { READ_DOCUMENT } from "../../../services/queries/mutations/client/readDocument";
import { documentLoadingSliceActions } from "../model/documentLoadingSlice";
import { APPLICATION_INFO } from "../../../services/queries/queries/applications/applicationInfo";

export const HotDocument = () => {
  const [documentAction, setDocumentAction] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentUuid, setDocumentUuid] = useState("");
  const [confirmFile, setConfirmFile] = useState("");
  const [showEds, setShowEds] = useState(null)
  const [showCommentField, setShowCommentField] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const { register, watch, setValue, handleSubmit } = useForm();

  const [updateDocuments] = useMutation(
    UPDATE_PERSONAL_DOCUMENTS,
    {
      refetchQueries: [CLIENT_INFO_PROFILE],
    }
  );

  const [readDocument] = useMutation(
    READ_DOCUMENT,
    {
      refetchQueries: [CLIENT_INFO_PROFILE],
      
    }
  );

  const uploadFile = watch("documentUpload");

  const handleClick = async(formData) => {
    dispatch(documentLoadingSliceActions.setLoading(true))
    await updateDocuments({
      variables: {
        personDocumentUuid: documentUuid,
        file: formData.documentUpload[0],
      },
      skip: !documentUuid,
    }).then(() => {
      navigate("/client-cabinet/")
      dispatch(documentLoadingSliceActions.setLoading(false))
    })
  };

  const handleReadDocumentClick = () => {
    dispatch(documentLoadingSliceActions.setLoading(true))
    readDocument({
      variables: {
        personDocumentUuid: documentUuid,
        description: "",
        isConfirmed: true,
      },

    }).then(() => {
      if(showEds === 'false'){
        localStorage.setItem("edsStatus", "true");
      }
      
      navigate("/client-cabinet/")
    }).finally(dispatch(documentLoadingSliceActions.setLoading(false)));

  };

  const documentActionVariantRender = useMemo(() => {
    if (documentAction === "REQUEST_DOCUMENT" || documentAction === "request_document") {
      return (
        <div className={styles.hotDocumnetContainer}>
          <h3 className={styles.documentTitle}>
            Загрузите документ {documentTitle}
          </h3>
          <form onSubmit={handleSubmit(handleClick)} className={styles.hotDocumentFotm}>
            <FileField
              setValue={setValue}
              labelClassName={styles.hotFileFieldLabel}
              className={styles.hotFileField}
              register={register}
              name="documentUpload"
            />
            <Button variant="green" type="submit">
              Отправить
            </Button>
          </form>
        </div>
      );
    }

    if (documentAction === "CONFIRM_DOCUMENT") {
      return (
        <>
          <div className={styles.confirmWrapper}>
            <h3 className={styles.contractContentTitle}>
              Договор для ознакомления
            </h3>
            <div className={styles.viewerWrapper}>
              <DocumentViewer fileUrl={confirmFile} />
            </div>

            {showCommentField ? (
              <ContractComment
                className={styles.confirmButtons}
                documentUuid={documentUuid}
                setShowComment={setShowCommentField}
              />
            ) : (
              <div className={styles.confirmInner}>
                <div className={styles.confirmButtons}>
                  <div>
                    <Button
                      variant="green"
                      onClick={handleReadDocumentClick}
                      className={styles.confirmBtn}
                    >
                      Ознакомлен
                    </Button>
                    <span>
                      Нажимая на кнопку "Ознакомлен" вы подтверждаете, что в
                      договоре все ваши данные верны.
                    </span>
                  </div>
                  <div>
                    <Button
                      variant="green"
                      onClick={() => setShowCommentField(true)}
                      className={styles.confirmBtn}
                    >
                      Отправить на доработку
                    </Button>
                    <span>
                      Если нашли ошибку в договоре отправьте на доработку и
                      оставьте комментарий.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }

    if(!documentAction){
      return(
        <span>Ошибка при загрузке</span>
      )
    }
  }, [documentAction, showCommentField, setShowCommentField]);

  useEffect(() => {
    if (location.state && location.state.action) {
      setDocumentAction(location.state.action);
    }

    if (location.state && location.state.documentTitle) {
      setDocumentTitle(location.state.documentTitle);
    }

    if (location.state && location.state.documentUuid) {
      setDocumentUuid(location.state.documentUuid);
    }

    if (location.state && location.state.confirmFile) {
      setConfirmFile(location.state.confirmFile);
    }

    if(location.state && location.state.showEds){
      setShowEds(location.state.showEds)
    }

  }, [location]);

  return (
    <div className={styles.hotDocumentWrapper}>
      <Button
        className={styles.hotDocumentPrevBtn}
        onClick={() => navigate(`/client-cabinet/`)}
      >
        <span>{"<"}</span>
        Кабинет
      </Button>
      {documentActionVariantRender}
    </div>
  );
};
