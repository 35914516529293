import {useCallback, useContext, useEffect, useState} from "react";
import {Input} from "../../../Input";
import styles from "./CreateCompanyForm.module.scss";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {RepresentativesList} from "../../../RepresentativesList";
import {BaseSearch} from "../../../BaseSearch";
import {Context} from "../../../../App";
import {Button} from "../../../Button";
import {checkValidation} from "../../../../helpers/checkValidation";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CREATE_COMPANY} from "../../../../services/queries/queries/companies/createCompany";
import {ALL_COMPANIES} from "../../../../services/queries/queries/companies/allCompaniesList";
import {COMPANY_INFO} from "../../../../services/queries/queries/companies/companyInfo";
import {UPDATE_COMPANY} from "../../../../services/queries/queries/companies/updateCompanyInfo";
import {DELETE_COMPANY} from "../../../../services/queries/mutations/companies/deleteCompany";
import {GET_ADDRESSES} from "../../../../services/queries/queries/search";
import {useDebouncedCallback} from "use-debounce";
import {ADD_REPRESENTATIVE} from "../../../../services/queries/queries/companies/addCompanyRepresentatives";
import {InnSearch} from "../../../InnSearch";
import {
  ADD_AUTHORITY_LEGAL_RECORD_TO_REPRESENTATIVE,
  CREATE_AUTHORITY_RECORD
} from "../../../../services/queries/mutations/companies/legalRepresentativeFileQueries";
import {selectRepresentatives} from "../../../../redux/slices/legalPerson";
import {SearchAddressInput} from "../SearchAddressInput/SearchAddressInput";
import useInputSearch from "../../../../hooks/useInputSearch";
import { getHashFromXml } from "../../../../helpers/getHashFromXml";

export const CreateCompanyForm = ({ id, uuid, hideModal }) => {
  const { data } = useQuery(COMPANY_INFO, {
    variables: {
      id,
    },

    skip: !id,
  });
  const companyRepresentatives = useSelector(selectRepresentatives);
  const [representatives, setRepresentatives] = useState([]);
  const [address, setAddress] = useState("");
  const [innSearch, setInnSearch] = useState([]);
  const [show, setShow] = useState(false);
  const [legalUuids, setLegalUuids] = useState("");
  const {
    search,
    setSearch,
    data: searchData,
  } = useInputSearch("", "GET_ADDRESSES");
  const { setShowModal } = useContext(Context);
  const numbersValidation = /^[0-9]+$/;
  const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

  const [createLegalAuthorityRecord] = useMutation(CREATE_AUTHORITY_RECORD, {
    refetchQueries: [{ query: ALL_COMPANIES }],
    skip: !uuid,
  });

  const [addAuthorityLegalRecordToRepresentative] = useMutation(
    ADD_AUTHORITY_LEGAL_RECORD_TO_REPRESENTATIVE,
    {
      onCompleted: (data) => {},
      refetchQueries: [
        { query: ALL_COMPANIES },
        { query: COMPANY_INFO, variables: { id }, skip: !id },
      ],
      skip: !uuid,
    }
  );

  const [addressError, setAddressError] = useState(false);
  const createLegalAuthorityRecordHandle = useCallback(
    async (item, physicalPersonUuid, legalPersonUuid) => {
      try {
        let variables = {
          file: item?.file,
          typeUuid: item?.documentType,
        };
        if (item?.typePeriodStart && item?.typeperiodEnd) {
          variables.durationStart = item?.typePeriodStart;
          variables.durationStop = item?.typeperiodEnd;
        }

        if (item?.signatureFile) {
          variables.sigFile = item?.signatureFile;
        }
        const legalAuthorityRecordData = await createLegalAuthorityRecord({
          variables: variables,
        });
        if (
          legalAuthorityRecordData.data.createLegalAuthorityRecord
            ?.legalAuthorityRecord?.uuid
        ) {
          addAuthorityLegalRecordToRepresentative({
            variables: {
              physicalPersonUuid: physicalPersonUuid,
              legalPersonUuid: legalPersonUuid,
              legalAuthorityRecordUuid:
                legalAuthorityRecordData.data.createLegalAuthorityRecord
                  ?.legalAuthorityRecord?.uuid,
            },
          });
        }

        if(legalAuthorityRecordData?.data?.createLegalAuthorityRecord.legalAuthorityRecord?.file){
          const hash = getHashFromXml(legalAuthorityRecordData?.data?.createLegalAuthorityRecord.legalAuthorityRecord?.file)
          console.log(hash)
        }
      } catch (error) {
        console.log(error);
      }
    },
    [addAuthorityLegalRecordToRepresentative,createLegalAuthorityRecord]
  );

  const createCompanyRepresentativesAuthorityRecord = useCallback(
    async (legalPersonUuid) => {
      try {
        companyRepresentatives?.forEach((item) => {
          const physicalUuid = item?.uuid || item?.physicalPerson?.uuid;
          setLegalUuids([...legalUuids, physicalUuid]);
          if (
            item?.legalAuthorityRecord?.file &&
            typeof item?.legalAuthorityRecord?.file !== "string"
          ) {
            createLegalAuthorityRecordHandle(
              item?.legalAuthorityRecord,
              physicalUuid,
              legalPersonUuid
            );
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    [companyRepresentatives, createLegalAuthorityRecordHandle, legalUuids]
  );

  const [createCompany] = useMutation(CREATE_COMPANY, {
    onCompleted: (data) => {
      if (data?.createLegalPerson?.legalPerson?.uuid) {
        createCompanyRepresentativesAuthorityRecord(
          data?.createLegalPerson?.legalPerson?.uuid
        );
      }
    },
    refetchQueries: [ALL_COMPANIES],
  });
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    refetchQueries: [
      ALL_COMPANIES,
      { query: COMPANY_INFO, variables: { id }, skip: !id },
    ],
  });

  const [deleteCompanyFunc] = useMutation(DELETE_COMPANY, {
    refetchQueries: [ALL_COMPANIES],
  });

  const [addRepresentative] = useMutation(ADD_REPRESENTATIVE, {
    refetchQueries: [
      ALL_COMPANIES,
      { query: COMPANY_INFO, variables: { id }, skip: !id },
    ],
  });

  const [getAddress, { data: searchAddressData }] = useLazyQuery(GET_ADDRESSES);
  const [getCompanyAddress, { data: searchCompanyAdress }] = useLazyQuery(GET_ADDRESSES);
  const [searchResults, setSearchResults] = useState([]);
  const [choiceResult, setChoiceResult] = useState([]);

  const debounce = useDebouncedCallback((address) => {
    if (address && choiceResult?.length === 0) {
      getAddress({
        variables: {
          address: address.value,
        },
      });
    }
  }, 500);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setError,
  } = useForm({
    mode: "onChange",
  });

  const deleteCompany = () => {
    if (uuid) {
      deleteCompanyFunc({
        variables: { uuid },
      });
    }

    setShowModal(false);
    hideModal(null);
  };

  const choiceSearchVariantHandler = (item) => {
    if (item) {
      setAddress(item);
      setChoiceResult(item);
      setSearchResults([]);
      setShow(false);
    }
  };

  const [otherToAddress, setOtherToAddress] = useState(
    data ? data?.legalPerson?.address : {}
  ); // Вытекающие из адреса (locality, city etc)

  const formatAddress = [
    otherToAddress?.index || "",
    otherToAddress?.region || "",
    otherToAddress?.district || "",
    otherToAddress?.locality || otherToAddress?.locality !== "None None"
      ? otherToAddress?.locality
      : "",
    otherToAddress?.settlementWithType || "",
    otherToAddress?.locationName || "",
    otherToAddress?.street || "",
    otherToAddress?.house ? "дом " + otherToAddress?.house : "",
    otherToAddress?.block ? "корпус " + otherToAddress?.block : "",
    otherToAddress?.apartment ? "кв " + otherToAddress?.apartment : "",
  ]
    .filter(Boolean)
    .join(", ");

  const onSubmitHandler = async (formData) => {
    const inputLegal = {
      fullName: formData.companyName,
      shortName: formData.shortCompanyName,
      inn: formData.inn,
      ogrn: formData.ogrn,
      kpp: formData.kpp,
      email: formData.email,
      phoneNumber: formData.phone.replace(/[^\d+]/g, ""),
      representatives: representatives.map((item) => {
        return item.uuid;
      }),
    };
    const inputLegalUpdate = {
      fullName: formData.companyName,
      shortName: formData.shortCompanyName,
      inn: formData.inn,
      ogrn: formData.ogrn,
      kpp: formData.kpp,
      email: formData.email,
      phoneNumber: formData.phone.replace(/[^\d+]/g, ""),
    };

    const inputAddress = {
      value:
      formatAddress ? formatAddress : (searchResults?.length > 0
          ? searchResults[0].value
          : address?.value !== ""
          ? address?.value
          : ""),
      region:
        searchResults?.length > 0
          ? searchResults[0].region
          : choiceResult?.region,
      locality:
        otherToAddress?.locality ? otherToAddress?.locality : (searchResults?.length > 0
          ? searchResults[0].locality
          : choiceResult?.locality),
      district:
        otherToAddress?.district ? otherToAddress?.district : (searchResults?.length > 0
          ? searchResults[0].district
          : choiceResult?.district),
      street:
        otherToAddress?.street ? otherToAddress?.street : (searchResults?.length > 0
          ? searchResults[0].street
          : choiceResult?.street),
      streetType:
        otherToAddress?.streetType ? otherToAddress?.streetType : (searchResults?.length > 0
          ? searchResults[0].streetType
          : choiceResult?.streetType),
      house:
        otherToAddress?.house ? otherToAddress?.house : (searchResults?.length > 0 ? searchResults[0].house : choiceResult.house),
      building:
        otherToAddress?.buildingType ? otherToAddress?.buildingType : (searchResults
          .length > 0
          ? searchResults[0].buildingType
          : choiceResult?.buildingType),
      index:
        otherToAddress?.index ? otherToAddress?.index : (searchResults?.length > 0 ? searchResults[0].index : choiceResult?.index),
      block:
        otherToAddress?.block ? otherToAddress?.block : (searchResults.length > 0 ? searchResults[0].block : choiceResult?.block),
      city:
        otherToAddress?.city ? otherToAddress?.city : (searchResults?.length > 0 ? searchResults[0].city : choiceResult?.city),
      country:
        otherToAddress?.country ? otherToAddress?.country : (searchResults?.length > 0
          ? searchResults[0].country
          : choiceResult?.country),
      okato:
        searchResults?.length > 0 ? searchResults[0].okato : choiceResult?.okato,
      oktmo:
        searchResults.length > 0 ? searchResults[0].oktmo : choiceResult?.oktmo,
      regionType:
        searchResults?.length > 0
          ? searchResults[0].regionType
          : choiceResult?.regionType,
      structure:
        searchResults?.length > 0
          ? searchResults[0].structure
          : choiceResult?.structure,
      regionKladrId: searchResults?.length > 0 ? searchResults[0].regionKladrId : choiceResult.regionKladrId,
      kladr: searchResults?.length > 0 ? searchResults[0].kladr : choiceResult.kladr,
      fias: searchResults?.length > 0 ? searchResults[0].fias : choiceResult.fias,
      apartment: otherToAddress?.apartment ? otherToAddress?.apartment : (searchResults?.length > 0 ? searchResults[0].apartment : choiceResult?.apartment),
    };
    try {
      if (!uuid) {
        await createCompany({
          variables: {
            inputAddress,
            inputLegal,
          },
        });
      } else {
        await updateCompany({
          variables: {
            uuid: uuid,
            inputAddress,
            inputLegal: inputLegalUpdate,
          },
        });
      }
      if (!errors?.root?.serverError) {
        setShowModal(false);
        if (hideModal) {
          hideModal(null);
        }
      }
    } catch (error) {
      setError("root.serverError", {
        type: "Ошибка при отправке запроса",
      });
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) =>
          setError("root.serverError", {
            type: message.split(".")[0],
          })
        );
      }
      if (error.networkError) {
        setError("root.serverError", {
          type: "Received status code 400",
        });
        console.log(`[Network error]: ${error.networkError}`);
      }
    }
  };

  useEffect(() => {
    debounce(address);
  }, [address]);

  useEffect(() => {
    if (innSearch?.length !== 0) {
      reset({
        companyName: innSearch[0].fullName,
        shortCompanyName: innSearch[0].shortName,
        ogrn: innSearch[0].ogrn,
        kpp: innSearch[0].kpp,
      });
      getCompanyAddress({
        variables: {
          address: innSearch[0].address.value,
        },
      });
    }
  }, [innSearch]);

  useEffect(() => {
    if (searchCompanyAdress) {
      setAddress(searchCompanyAdress?.getAddresses[0]);
      setOtherToAddress(searchCompanyAdress?.getAddresses[0]);
    }
  }, [searchCompanyAdress]);


  useEffect(() => {
    if (searchAddressData) {
      setSearchResults(searchAddressData.getAddresses);
    }
  }, [searchAddressData]);

  useEffect(() => {
    if (data && data.legalPerson) {
      reset({
        companyName: data.legalPerson.fullName,
        shortCompanyName: data.legalPerson.shortName,
        ogrn: data.legalPerson.ogrn,
        inn: data.legalPerson.inn,
        kpp: data.legalPerson.kpp,
        email: data.legalPerson.email,
        phone: data.legalPerson.phoneNumber.replace(/[^\d+]/g, ""),
      });

      setAddress(data.legalPerson.address);
    }
  }, [data, address, choiceResult]);

  useEffect(() => {
    if (representatives?.length > 0 && uuid) {
      addRepresentative({
        variables: {
          representatives: representatives.map((item) => {
            return item.uuid;
          }),
          uuid,
        },

        skip: !uuid,
      });
    }
  }, [representatives, addRepresentative, uuid]);
  return (
    <>
      <div className={styles.formInner}>
        <form onSubmit={handleSubmit(onSubmitHandler)} id="companyForm">
          <div className={styles.companyInfo}>
            <Input
              isFormField={true}
              name="companyName"
              label="Полное название"
              variant="bordered-green"
              size="full"
              register={register}
              watch={watch}
              registerObj={{
                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
            />
            <Input
              isFormField={true}
              name="shortCompanyName"
              label="Краткое название"
              variant="bordered-green"
              size="full"
              register={register}
              registerObj={{
                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
            />

            <Input
              isFormField={true}
              name="ogrn"
              label="ОГРН"
              variant="bordered-green"
              size="full"
              mask="9999999999999"
              placeholder="000000000000"
              register={register}
              registerObj={{
                maxLength: {
                  value: 13,
                  message: "Максимум 13 цифр",
                },

                required: "Поле обязательно для заполнения",
              }}
              onKeyPress={(e) => {
                checkValidation(e, numbersValidation);
              }}
              errors={errors}
            />

            <InnSearch
              setInnData={setInnSearch}
              register={register}
              registerObj={{
                required: "Поле обязательно для заполнения",
              }}
              watch={watch}
            />

            <Input
              isFormField={true}
              name="kpp"
              label="КПП"
              variant="bordered-green"
              size="full"
              mask="999999999"
              placeholder="0000000000000"
              register={register}
              registerObj={{
                maxLength: {
                  value: 13,
                  message: "Максимум 13 символов",
                },

                required: "Поле обязательно для заполнения",
              }}
              errors={errors}
            />

            <div className={styles.addressBox}>
              {/* <span>Адрес регистрации</span> */}
              <SearchAddressInput
                defaultAddress={address}
                data={searchData}
                setSearch={setSearch}
                defaultValues={address ? address : (data?.legalPerson.address ? data?.legalPerson.address : {})}
                setOtherToAddress={setOtherToAddress}
                otherToAddress={otherToAddress}
                formatAddress={formatAddress}
                addressError={addressError}
                setAddressError={setAddressError}
              />

              {searchResults?.length > 0 && show && (
                <ul className={styles.addressList}>
                  {searchResults.map((result, index) => (
                    <li
                      key={index}
                      onClick={() => choiceSearchVariantHandler(result)}
                    >
                      {result.value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* 
            <Button
              type="button"
              variant="transparent"
              className={styles.fullAddressBtn}
              onClick={() => {
                setFullAddress(!isFullAddress);
                setShow(false);
              }}
            >
              Уточнить адрес
            </Button>

            {isFullAddress && (
              <CreateFullAddressForm
                register={register}
                watch={watch}
                setAddress={setAddress}
              />
            )} */}

            <div className={styles.companyContacts}>
              <p className={styles.contactsTitle}>Контакты</p>
              <Input
                isFormField={true}
                name="email"
                label="Email"
                variant="bordered-green"
                size="full"
                register={register}
                registerObj={{
                  pattern: {
                    value: emailValidation,
                    message: "Введите коректынй e-mail",
                  },

                  required: "Поле обязательно для заполнения",
                }}
                errors={errors}
                placeholder="test@mail.ru"
              />
              <Input
                isFormField={true}
                name="phone"
                label="Телефон"
                mask="+9(999) 999 - 99 - 99"
                variant="bordered-green"
                size="full"
                register={register}
                registerObj={{
                  required: "Поле обязательно для заполнения",
                }}
                errors={errors}
                placeholder="+7(000) 000-00-00"
              />
            </div>
          </div>
        </form>

        <div className={styles.representativesInfo}>
          <h3 className={styles.representativesTitle}>Представители</h3>
          <BaseSearch
            targetArr={representatives}
            setTargetArr={setRepresentatives}
            tooltipClassName={styles.tooltip}
          />
          {data &&
          data.legalPerson &&
          Array.isArray(data?.legalPerson?.representatives) &&
          data?.legalPerson?.representatives?.length
            ? data?.legalPerson?.representatives?.map((item) => (
                <RepresentativesList
                  item={item}
                  data={data?.legalPerson?.representatives}
                  setData={setRepresentatives}
                  key={item?.physicalPerson?.uuid}
                  id={id}
                  uuid={uuid}
                  onCreateDocument={createCompanyRepresentativesAuthorityRecord}
                />
              ))
            : representatives.map((item) => (
                <RepresentativesList
                  onCreateDocument={createCompanyRepresentativesAuthorityRecord}
                  item={item}
                  data={representatives}
                  setData={setRepresentatives}
                  key={item}
                  id={id}
                  uuid={uuid}
                />
              ))}
        </div>
      </div>

      {errors?.root?.serverError?.type && (
        <p className={styles.formError}>Ошибка при отправке запроса</p>
      )}

      <Button
        type="submit"
        variant="green"
        form="companyForm"
        className={styles.formBtn}
      >
        Сохранить
      </Button>
      <Button
        variant="red"
        className={styles.deleteForm}
        onClick={deleteCompany}
      >
        Удалить юрлицо
      </Button>
    </>
  );
};
