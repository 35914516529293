import { gql } from "@apollo/client";

export const COMPANY_INFO = gql`
	query CompanyInfo($id: ID!) {
		legalPerson(id: $id) {
			fullName
			shortName
			email
			phoneNumber
			inn
			ogrn
			kpp
			representatives {
				legalAuthorityRecord {
					durationStart
					durationStop
					file
					id
					presignedUrlFile
					presignedUrlSigFile
					sigFile
					uuid
					type {
						name
						uuid
					}
				}
				physicalPerson {
					uuid
					firstName
					lastName
					patronymic
					snils
					document {
						type
						series
						number
						dateIssue
						issuedBy
						code
					}
				}
			}
			address {
				id
				region
				regionType
				district
				locality
				house
				building
				structure
				okato
				oktmo
				index
				city
				street
				streetType
				country
				value
				block
				apartment
			}
		}
	}
`;
