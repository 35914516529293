import React, { useState, useEffect } from "react";
import { Link, useNavigate, useMatch } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import styles from "./AuthForm.module.scss";
import { Input } from "../../../Input/ui/Input";
import { Button } from "../../../Button/ui/Button";
import { Eye } from "../../../../assets/icons/Eye";
import { AUTH } from "../../../../services/queries";
import { setTokens } from "../../../../redux/slices/user";

export const AuthForm = ({ setRestorePassword }) => {
  const dispatch = useDispatch();

  const [passwordType, setPasswordType] = useState("password");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorTextLogin, setErrorTextLogin] = useState("");
  const [isPhone, setIsPhone] = useState(false);
  const navigate = useNavigate();

  // const { loading, error, data } = useQuery(USER, {
  // 	onError(err) {
  // 		if (err?.message === "Signature has expired") {
  // 			refreshTokenHandle();
  // 		}
  // 	},
  // 	context: {
  // 		headers: {
  // 			Authorization: `JWT ${token}`,
  // 		},
  // 	},
  // });

  const [mutateFunction, { data, loading, error }] = useMutation(AUTH, {
    onCompleted: (data) => {
      localStorage.setItem("id", data.auth.user.id);
      localStorage.setItem("role", data?.auth?.user?.role);
      dispatch(setTokens(data?.auth?.token, data?.auth?.refreshToken));
      localStorage.setItem("accessToken", data?.auth?.token);
      localStorage.setItem("refreshToken", data?.auth?.refreshToken);
    },
  });
  const regEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const regPhone = /^\+?[8|7] [-\(]?\d{3}\) ?-?\d{3}-?\d{2}-?\d{2}$/;
  const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,16}/;

  const match = useMatch("/reset-password/:token");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onChange",
  });
  const watchedPhone = watch("phone");

  const onPasswordButtonClick = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const onSubmit = (formData) => {
    mutateFunction({
      variables: {
        password: formData.password,
        login: formData.email ? formData.email : formData.phone,
      },
    }).catch((error) => {
      setErrorTextLogin(error?.message);
    });
  };

  useEffect(() => {
    if (data?.auth) {
      dispatch(setTokens(data.auth));
    }
  }, [data?.auth]);

  const onChooseType = () => {
    setIsPhone(!isPhone);

    if (isPhone) {
      setValue("phone", null);
    } else {
      setValue("email", null);
    }
  };

  useEffect(() => {
    if (data && data?.auth?.user?.role === "CL") {
      navigate(`/client-cabinet/`);
    }

    if (
      (data && data?.auth?.user?.role === "MA") ||
      data?.auth?.user?.role === "AD"
    ) {
      navigate("/applications/");
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.authForm}>
      <h3 className={styles.title}>Добро пожаловать!</h3>
      <h4 className={styles.subtitle}>
        Войдите с{" "}
        <span
          className={
            isPhone === true ? styles.span_contact : styles.span_contact__active
          }
          onClick={onChooseType}
        >
          почтой
        </span>
        /
        <span
          className={
            isPhone === true ? styles.span_contact__active : styles.span_contact
          }
          onClick={onChooseType}
        >
          телефоном
        </span>
      </h4>
      {isPhone && (
        <Input
          id="phone"
          name="phone"
          type="tel"
          mask="+9(999) 999-99-99"
          label="Телефон"
          maxLength={watchedPhone?.match(/^\+/gi) ? 18 : 17}
          errors={errors}
          isFormField={true}
          errorVariant="absolute"
          register={register}
          className={styles.input}
          registerObj={{
            required: "Укажите номер телефона",
            // pattern: {
            //   value: regPhone,
            //   message: `Укажите корректный номер телефона`,
            // },
            // onChange: (evt) => {
            //   const value = evt.target.value;
            //   const newValue = value.replace(
            //     /(\d{1})(\d{3})(\d{3})(\d{2})(\d)+$/,
            //     "$1 ($2) $3-$4-$5"
            //   );
            //   setValue(`phone`, newValue);
            //   clearErrors(`phone`);
            //   if (error?.message) {
            //     setErrorTextLogin("");
            //   }
            // },
          }}
        />
      )}
      {!isPhone && (
        <Input
          id="email"
          name="email"
          type="email"
          label="Почта"
          errors={errors}
          isFormField={true}
          errorVariant="absolute"
          className={styles.input}
          register={register}
          registerObj={{
            required: "Укажите почту",
            pattern: {
              value: regEmail,
              message: `Укажите корректный e-mail`,
            },
            onChange: () => {
              clearErrors(`email`);
              if (error?.message) {
                setErrorTextLogin("");
              }
            },
          }}
        />
      )}
      
      <div className={styles.input}>
        <div className={styles.passwordInputContainer}>
          <Input
            id="password"
            name="password"
            type={passwordType}
            errors={errors}
            label="Пароль"
            errorVariant="absolute"
            isFormField={true}
            className={styles.input}
            register={register}
            registerObj={{
              required: "Укажите пароль",
              pattern: {
                value: regPassword,
                message: `Пароль не соответствует требованиям`,
              },
              onChange: () => {
                clearErrors(`password`);
                if (error?.message) {
                  setErrorTextLogin("");
                }
              },
            }}
          />
          <Button
            variant="transparent"
            className={styles.passwordButton}
            type="button"
            onClick={onPasswordButtonClick}
          >
            <Eye />
          </Button>
        </div>
        {errorTextLogin ? (
          <div className={styles.errorPassword}>
            <span>Неверные данные для входа</span>
            <span>
              <Link to="/passwordRecovery">Отправить ссылку</Link> на
              восстановление пароля
            </span>
          </div>
        ) : !match ? (
          <Button
            className={styles.resetPasswordButton}
            variant="transparent"
            onClick={() => setRestorePassword(true)}
            type="button"
          >
            Восстановить пароль...
          </Button>
        ) : (
          <div className={styles.loginClient__formWrapper__resetPassword}></div>
        )}
      </div>
      {match && (
        <div className={styles.textPassword}>
          <p>- Используйте только латинские буквы (a–z, A–Z)</p>
          <p>
            - Пароль должен содержать хотя бы один специальный символ: <br />! @
            # $ % ^ & * ( ) _ + = ; : , . / ?| ` ~
          </p>
          <p>
            - Ваш пароль должен содержать от 8 до 16 символов <br />с
            использованием цифр,строчных и заглавных символов.
          </p>
        </div>
      )}
      <Button
        className={styles.submitBtn}
        variant="green"
        type="submit"
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("id");
          localStorage.removeItem("role");
        }}
        disabled={Object.keys(errors).length > 0}
      >
        {match ? "Создать кабинет" : "Войти в кабинет"}
      </Button>
    </form>
  );
};
