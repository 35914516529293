import { memo, useEffect, useState } from "react";
import styles from "./CopyApplication.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DUPLICATE_APPLICATION } from "../../../services/queries/mutations/applications/duplicateApplication";
import { CopyIcon } from "../../../assets/icons/CopyIcon";
import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";
import { ArrowLeft } from "../../../assets/icons/ArrowLeft";
import { TypeInfoForm } from "./TypeInfoForm/TypeInfoForm";
import { ObjectBlock } from "../../../components/Forms/ui/ApplicationForm/ui/ObjectBlock/ObjectBlock";
import { ParticipantBlock } from "../../../components/Forms/ui/ApplicationForm/ui/ParticipantBlock/ParticipantBlock";
import { DocumentsBlock } from "../../../components/Forms/ui/ApplicationForm/ui/DocumentsBlock/DocumentsBlock";
import { APPLICATION_INFO } from "../../../services/queries/queries/applications/applicationInfo";
import { DELETE_APPLICATION } from "../../../services/queries/mutations/applications/deleteApplication";
import { Preloader } from "../../../components/Preloader";
import { CreateStatement } from "./CreateStatement/CreateStatement";
import { DeclarationBlock } from "../../../components/Forms/ui/ApplicationForm/ui/DeclarationBlock/DeclarationBlock";
import {
  DELETE_DOCUMENT,
  SEND_DOCUMENTS_FOR_SIGN,
} from "../../../services/queries/mutations/documents/documents";
import { toast } from "react-toastify";
import { SendToReestr } from "../ui/SendToReestr/SendToReestr";
import { StatementStatuses } from "./StatementStatuses/StatementStatuses";
import { AddFileGreen } from "../../../assets/icons/AddFileGreen";
import { formatDate } from "../../../helpers/formatDate";
import { PaymentsBlock } from "../../../components/Forms/ui/ApplicationForm/ui/PaymentsBlock/PaymentsBlock";
import { MassiveSign } from "../../../components/MassiveSign/ui/MassiveSign";


export const CopyApplication = memo(() => {
  const { id: applicationId } = useParams();
  const [applicationInfo, { data: applicationData, loading }] = useLazyQuery(
    APPLICATION_INFO,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [
    duplicateApplication,
    { data: duplicateData, loading: duplicateLoading },
  ] = useMutation(DUPLICATE_APPLICATION);
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState([]);
  const [objectInfo, setObjectInfo] = useState(null);
  const applicationSended =
    applicationData?.application?.requestHistory?.length === 0;

  const [deleteApplication] = useMutation(DELETE_APPLICATION);
  

  const applicationDeleteHandler = () => {
    if (applicationData?.application?.uuid) {
      deleteApplication({
        variables: {
          uuid: applicationData?.application?.uuid,
        },
      }).then(() => navigate("/applications/"));
    } else {
      navigate("/applications/");
    }
  };

  const duplicateApplicationHandler = () => {
    duplicateApplication({
      variables: { applicationUuid: applicationData?.application?.uuid },
    }).then((data) =>
      navigate(
        `/copy-application/${data?.data?.duplicateApplication?.application?.id}`
      )
    );
  };

  const [sendDocumentsForSign] = useMutation(SEND_DOCUMENTS_FOR_SIGN, {
    onCompleted: (data) => {
      toast.success(data.sendDocuments.message);
    },
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    onCompleted: (data) => {},
    onError(err) {
      console.log(err?.message);
    },
  });

  useEffect(() => {
    if (applicationId) {
      applicationInfo({
        variables: {
          id: applicationId,
        },
      });
    }
  }, [applicationId, applicationInfo]);

  useEffect(() => {
    if (applicationData && applicationData?.application?.documents) {
      setDocuments(applicationData?.application?.documents);
    }

    if (applicationData && applicationData?.application?.realObject) {
      setObjectInfo(applicationData?.application?.realObject);
    }

    if (applicationData && applicationData?.application?.requestHistory) {
      setRequestStatuses(applicationData?.application?.requestHistory);
    }
  }, [applicationData]);

  useEffect(() => {
    const socketLink = process.env.REACT_APP_API_BASE_URL_WS;
    const token = localStorage.getItem("accessToken");
    const fullSocketLink = socketLink + `?token=${token}`;
    const socket = new WebSocket(fullSocketLink);

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (
        data.type === "document_change_state" ||
        data.type === "signature_change_state"
      ) {
        applicationInfo({
          variables: {
            id: applicationId,
          },
        });
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.copyApplicationContainer}>
        <Link to="/applications/" className={styles.backBtn}>
          <ArrowLeft /> <span>Кабинет</span>
        </Link>
        <h3 className={styles.applicationsCopyTitle}>
          {applicationData?.application?.type?.title}
          {duplicateLoading ? (
            <Preloader variant="small" className={styles.copySmallPreloader} />
          ) : (
            <abbr
              title="Копировать заявку"
              className={styles.copyIcon}
              onClick={duplicateApplicationHandler}
            >
              {" "}
              <span>
                <CopyIcon />{" "}
              </span>{" "}
            </abbr>
          )}
        </h3>
        <h3 className={styles.sectionTitle}>
          {applicationData?.application?.dealParty === "UNISTROY"
            ? "Сделка с Унистроем"
            : "Сделка третьих лиц"}
        </h3>
        <TypeInfoForm typeData={applicationData?.application} />
        <div className={styles.objectInfo}>
          <h3 className={styles.sectionTitle}>Объект</h3>
          {loading ? (
            <Preloader />
          ) : (
            <ObjectBlock
              applicationData={objectInfo}
              isCopy={true}
              applicationId={applicationId}
              applicationInfo={applicationInfo}
            />
          )}
        </div>
        <div className={styles.participantsInfo}>
          <h3 className={styles.sectionTitle}>Участники</h3>
          <ParticipantBlock
            applicationType={applicationData?.application?.type?.uuid}
            allParticipant={applicationData?.application?.participants}
            isCopy={true}
            lawType={applicationData?.application?.ownership?.uuid}
            applicationInfo={applicationInfo}
            applicationId={applicationData?.application?.id}
          />
        </div>
        <div className={styles.documentsInfo}>
          <h3 className={styles.sectionTitle}>Документы</h3>
          {applicationSended && (
            <Button
              variant="outline-green"
              className={styles.addBtn}
              type="button"
              onClick={() => {
                setDocuments([{ id: documents.length + 1 }, ...documents]);
              }}
            >
              <span>Добавить</span>
            </Button>
          )}

          {documents.map((item, index) => (
            <DocumentsBlock
              key={item?.uuid}
              id={index}
              participants={applicationData?.application?.participants}
              fileUrl={item?.presignedUrl}
              documentsIds={item?.documentsSignatures}
              documents={documents}
              deleteDocument={deleteDocument}
              setDocuments={setDocuments}
              documentComplete={applicationData?.application?.documentComplete}
              item={item}
              title={item?.title}
              isCopy={true}
              applicationSended={applicationSended}
              applicationUuid={applicationData?.application?.uuid}
              applicationId={applicationData?.application?.id}
              applicationInfo={applicationInfo}
            />
          ))}
        </div>
        <div className={styles.statementsInfo}>
          <h3 className={styles.sectionTitle}>Заявление</h3>
          {applicationSended && (
            <CreateStatement
              uuid={applicationData?.application?.uuid}
              applicationSended={applicationSended}
              applicationInfo={applicationInfo}
              applicationId={applicationId}
            />
          )}

          <div className={styles.statementsContainer}>
            {applicationData?.application?.statement?.map((item, index) => (
              <DeclarationBlock
                key={index}
                declaration={item}
                participants={applicationData?.application?.participants}
                applicationId={applicationData?.application?.id}
                applicationInfo={applicationInfo}
              />
            ))}
          </div>
          {applicationData?.application?.statement?.length > 0 &&
            !applicationData?.application?.documentComplete && (
              <div className={styles.signButtonsBox}>
                <Button
                  variant="white"
                  type="button"
                  className={styles.addBtn}
                  onClick={() => {
                    sendDocumentsForSign({
                      variables: {
                        applicationUuid: applicationData?.application?.uuid,
                      },
                    });
                  }}
                >
                  <span>Отправить на подпись</span>
                </Button>
                <MassiveSign
                  participants={applicationData?.application?.participants}
                />
              </div>
            )}
          <SendToReestr
            uuid={applicationData?.application?.uuid}
            applicationId={applicationData?.application?.id}
            setStatuses={setRequestStatuses}
            documentComplete={applicationData?.application?.documentComplete}
            statement={applicationData?.application?.statement}
            requestHistory={applicationData?.application?.requestHistory}
            kuvd={applicationData?.application?.kuvd}
          />
        </div>

        <div className={styles.paymentsInfo}>
          {applicationData?.application?.payments.length !== 0 && (
            <section className={styles.section}>
              <h3 className={styles.sectionTitle}>Пошлина</h3>
              {applicationData?.application?.payments.map((item, index) => (
                <PaymentsBlock
                  key={index}
                  payment={item}
                  participants={applicationData?.application?.participants}
                  requestPackage={applicationData?.application?.requestPackage}
                  status={applicationData?.application?.status}
                  uuid={applicationData?.application?.uuid}
                  applicationInfo={applicationInfo}
                  id={applicationData?.application?.id}

                />
              ))}
            </section>
          )}
        </div>

        <div className={styles.statementStatusesInfo}>
          <StatementStatuses
            requestHistory={requestStatuses}
            setStatuses={setRequestStatuses}
            applicationUuid={applicationData?.application?.uuid}
            applicationId={applicationData?.application?.id}
            kuvd={applicationData?.application?.kuvd}
            application={applicationData?.application}
          />
        </div>

        <div className={styles.additionalStatementInfo}>
          {requestStatuses.length > 0 ||
          applicationData?.application?.requestHistory?.length > 0 ? (
            <>
              <h3 className={styles.sectionTitle}>Дополнительное обращение</h3>
              <ul className={styles.additional}>
                {applicationData?.application?.additionalApplications?.map(
                  (item, index) => (
                    <li
                      key={index}
                      onClick={() => navigate(`/additional/edit/${item?.id}`)}
                    >
                      <div className={styles.card}>
                        <h4 className={styles.divTitle}>
                          {" "}
                          {item?.newApplication?.type?.title}{" "}
                        </h4>
                        <span className={styles.divText}>
                          {formatDate(item?.createdAt)} -{" "}
                          {item?.newApplication?.kuvd !== null
                            ? `Присвоен КУВД ${item?.newApplication?.kuvd}`
                            : `КУВД не присвоен`}
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ul>
              <Button
                variant={"extra-light-green"}
                className={styles.buttonGreen}
                type="button"
                onClick={() => navigate(`/additional/${applicationId}`)}
              >
                Дополнительное обращение... <AddFileGreen />
              </Button>
            </>
          ) : null}
        </div>
        <div className={styles.copyDelete}>
          <Button
            variant="red"
            onClick={applicationDeleteHandler}
            className={styles.deleteCopy}
          >
            Удалить обращение
          </Button>
          <span className={styles.deleteInfo}>
            Вместе с основным обращением удалятся все дополнительные
          </span>
        </div>
      </div>
    </div>
  );
});
