import { gql } from "@apollo/client";

export const FILTERED_APPLICATIONS = gql`
  query FilteredApplications(
    $status: String
    $typeSearch: String
    $search: String
    $dateRange: String
    $dealParty: String
    $first: Int
    $offset: Int
  ) {
    allApplications(
      status: $status
      typeSearch: $typeSearch
      search: $search
      dateRange: $dateRange
      dealParty: $dealParty
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          id
          uuid
          isCopy
          downloadZip
          requestPackage
          sum
          area
          ownership {
            id
            title
          }
          mortgage
          kuvd
          realObject {
            id
            cadastralNumber
            address {
              value
            }
          }
          type {
            id
            title
          }
          participants {
            id
            role {
              name
            }
            physicalPerson {
              firstName
              lastName
              patronymic
            }
            legalPerson {
              fullName
              inn
            }

            representative {
              firstName
              lastName
              patronymic
            }
          }
          documents {
            id
            title
          }
          status
          createdAt
          updatedAt
          requestSend
          additionalApplications {
            id
            uuid
            originalApplication {
              id
              uuid
            }
            createdAt
            updatedAt
            status
            comment
            newApplication {
              id
              uuid
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
