import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { INN_SEARCH } from "../../../services/queries/queries/companies/innSearch";
import { useForm } from "react-hook-form";
import { Input } from "../../Input";
import { checkValidation } from "../../../helpers/checkValidation";
import styles from "./InnSearch.module.scss";
import cn from "classnames";

export const InnSearch = ({ setInnData, register, watch }) => {
  const {
    formState: { errors },
    setError,
  } = useForm({
    mode: "all",
  });

  // const [query, setQuery] = useState("");
  const query = watch("inn");
  const numbersValidation = /^[0-9]+$/;
  const [innSearch, { data }] = useLazyQuery(INN_SEARCH, {
    fetchPolicy: "cache-first",
  });

  const debounce = useDebouncedCallback((query) => {
    if (query && query.trim()) {
      innSearch({
        variables: {
          inn: query,
        },
      });
    }
  }, 500);

  useEffect(() => {
    debounce(query);
  }, [query, debounce]);

  useEffect(() => {
    if (data && data.getExternalLegalPersons) {
      setInnData(data.getExternalLegalPersons);
    }
  }, [data]);

  return (
    <div className={styles.innWrapper}>
      <Input
        isFormField={true}
        name="inn"
        label="ИНН*"
        variant="bordered-green"
        size="full"
        className={cn({
          [styles.negativeSearch]:
            data && data?.getExternalLegalPersons?.length === 0 && query,
        })}
        placeholder="0000000000"
        register={register}
        registerObj={{
          maxLength: {
            value: 10,
            message: "Максимум 10 цифр",
          },
        }}
        onKeyPress={(e) => {
          checkValidation(e, numbersValidation);
        }}
        errors={errors}
      />
      {data && data?.getExternalLegalPersons?.length === 0 && query && (
        <p>ИНН не найден</p>
      )}
    </div>
  );
};
