import { useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { BaseFinderTemplate } from "../../../../../BaseFinderTemplate/BaseFinderTemplate";
import { Button } from "../../../../../Button";
import styles from "./CompanyRepresentativesBaseFinder.module.scss";
import { useQuery } from "@apollo/client";
import { ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST } from "../../../../../../services/queries/queries/representativesByPhysical/AllRepresentativesList";
import { newRoolParticipantRole } from "../../../../../../constants/constants";
import { setParticipant } from "../../../../../../redux/slices/application";

const ListRow = ({ data, onAdd }) => {
	return (
		<li
			className={styles.row}
		>
			<div className={styles.column}>
				<strong>Физлицо </strong>
				<strong>{data?.node?.representedPhysicalPerson?.lastName}  {data?.node?.representedPhysicalPerson?.firstName} {data?.node?.representedPhysicalPerson?.patronymic}</strong>
			</div>
			<div className={styles.column}>
				<strong>Юрлицо</strong>
				<strong>{data?.node?.legalPerson?.fullName}</strong>
				<strong>{data?.node?.representative?.lastName} {data?.node?.representative?.firstName} {data?.node?.representative?.patronymic}</strong>
			</div>
			<Button variant="green" type="button" onClick={() => onAdd(data?.node)}>
                Добавить
            </Button>
		</li>
	);
};

export const CompanyRepresentativesBaseFinder = ({
	setShow,
	participantType,
	setData,
	cardId,
	setIndividualData,
	setParticipantType,
}) => {
	const [search, setSearch] = useState("");
	const dispatch = useDispatch();

	const onAdd = useCallback((value) => {
		const newIndividual = {
			firstName: value?.representedPhysicalPerson?.firstName,
			lastName: value?.representedPhysicalPerson?.lastName,
			patronymic: value?.representedPhysicalPerson?.patronymic,
			uuid: value?.representedPhysicalPerson?.uuid,
			signaturesCurrent: value?.representedPhysicalPerson?.signaturesCurrent,
			participantType: participantType,
			role: newRoolParticipantRole,
		};

		const newRepresentative = {
			firstName: value?.representative?.firstName,
			lastName: value?.representative?.lastName,
			patronymic: value?.representative?.patronymic,
			uuid: value?.representative?.uuid,
			signaturesCurrent: value?.representative?.signaturesCurrent,
			participantType: participantType,
			role: newRoolParticipantRole,
		};

		const participant = {
		  fullName: value?.legalPerson?.fullName,
		  legalPerson: value?.legalPerson,
		  applicantUuid: value?.uuid,
		  id: cardId?.id,
		  participantType: "represented_physical_person",
		  representative: newRepresentative,
		  representedPhysicalPerson: newIndividual,
		  role: newRoolParticipantRole,
		};

		dispatch(setParticipant(participant));

		setData({id: cardId?.id, value});
		setParticipantType("represented_physical_person");
		setData(participant);
		setIndividualData(newIndividual)
		setShow(false);
	  }, [dispatch, setShow]);

	const { data } = useQuery(ALL_COMPANY_REPRESENTATIVES_BY_PERSON_LIST, {
		variables: {
			search: search,
			first: 10,
			offset: 0
		},
	});

	const array = data?.allLegalPersonRepresentedByPhysicalPersons?.edges;


	return (
		<BaseFinderTemplate
			inputLabel="Поиск по базе физлиц"
			isEmpty={!search}
			emptyText="Начните вводить имя, номер паспорта, снилс, или инн физлица"
			listClassnames={styles.resultList}
			withList={true}
			ListRow={ListRow}
			data={search !== "" ? array : []}
			onAdd={onAdd}
			setSearch={setSearch}
		>
		</BaseFinderTemplate>
	);
};
