import { useNavigate } from "react-router-dom";
import styles from "./EdsList.module.scss";
import cn from "classnames";
import { InfoList } from "../../../../InfoList";

export const EdsList = ({ data, className }) => {
  const navigate = useNavigate();

  return (
    <>
      {data?.signaturesCurrent?.length > 0 ? (
        <ul className={styles.edsList}>
          {data?.signaturesCurrent?.map((signature, index) => (
            <InfoList
              key={index}
              onClick={() => navigate("/eds-info/", {
                state: {
                  activeSignature: signature.isActive,
                  connector: signature.connector,
                  signatureUuid: signature.consumerUuid,
                  signaturesData: data?.signaturesCurrent
                },
                
              }
            )
            
          }
              className={cn(className)}
            >
              <div>
                <h3>{signature.connector}</h3>
                <span>{signature.isActive && 'Эта подпись активна'}</span>
              </div>
              <span className={styles.edsDescr}>Оформлена и выдана вам</span>
            </InfoList>
          ))}
        </ul>
      ) : (
        <InfoList
          className={className}
          onClick={() => navigate("/eds-info/")}
        >
          {data?.user?.physicalPerson?.signatureRelease?.isActive ? (
            <h3>Оформлена и выдана вам</h3>
          ) : (
            <h3>Не оформлена</h3>
          )}
          {!data?.user?.physicalPerson?.signatureRelease?.isActive ? (
            <span>Ждем вас в офисе</span>
          ) : null}
        </InfoList>
      )}
    </>
  );
};
