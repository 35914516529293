import { InfoList } from "../../../../InfoList";
import { useNavigate } from "react-router-dom";

/* 
  Компонент выводит список документов клиента по сделке

  Пропсы:

  className - помогает передать дополнительные стили для списка из родительского компонента

*/

export const ClientDocumentsList = ({className, clientData}) => {

  const navigate = useNavigate()


  return(
    <>
      {clientData?.map((document, index) => (
      <InfoList key={index} className={className} onClick={() => navigate(`/application-documents/${index + 1}`, {
        state: {documents: clientData}
      })}>
        <h3>{document?.document ? document?.document?.title : document?.title}</h3>
        <span>{document?.state === 'COMPLETE' ? 'Подписан' : 'Не подписан'}</span>
      </InfoList>
    ))}
    </>
  )
}