import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { Button } from "../../../../../Button";
import { ModalWindow } from "../../../../../ModalWindow";
import styles from "./StandartParticipantCard.module.scss";
import { BasketIcon } from "../../../../../../assets/icons/BasketIcon";
import { IndividualBaseFinder } from "../IndividualBaseFinder/IndividualBaseFinder";
import { Select } from "../../../../../Select/ui/Select";
import { Input } from "../../../../../Input";
import { EntityBaseFinder } from "../EntityBaseFinder/EntityBaseFinder";
import { Checkbox } from "../../../../../Checkbox";
import { downloadFile } from "../../../../../../helpers/downloadFileAfterUpload";
import { UPDATE_APPLICATION_PARTICIPANT } from "../../../../../../services/queries/mutations/applications/updateApplicationParticipants";
import { APPLICATION_INFO } from "../../../../../../services/queries/queries/applications/applicationInfo";
import { CREATE_AUTHORITY_RECORD } from "../../../../../../services/queries/mutations/companies/legalRepresentativeFileQueries";
import useFormatDocumentDate from "../../../../../../hooks/useFormatDocumentDate";
import { LEGAL_AUTHORITY_RECORD_TYPES } from "../../../../../../services/queries/mutations/companies/legalRepresentativeFileQueries";
import { UPDATE_SIGNATURE_ACTIVE } from "../../../../../../services/queries/mutations/applications/signatures/signatures";
import {
  setParticipantRole,
  setParticipantUpdated,
  setParticipantShare,
  setParticipant,
  deleteInnerParticipant,
} from "../../../../../../redux/slices/application";
import { SEND_DOCUMENT } from "../../../../../../services/queries/mutations/documents/documents";
import { toast } from "react-toastify";
import { FakeCheckbox } from "../../../../../FakeCheckbox";
import { downloadFileFromUrl } from "../../../../../../helpers/downloadFileFromUrl";

const FileCard = ({ url, name, size, fileFowDownload, onDelete, isCopy }) => {
  return (
    <div className={styles.fileAdded}>
      <div className={styles.fileInfo}>
        {typeof name === "string" && fileFowDownload ? (
          <span
            onClick={() => {
              downloadFile(fileFowDownload);
            }}
          >
            {name}
          </span>
        ) : (
          <span
            onClick={() => {
              downloadFileFromUrl(url, name);
            }}
          >
            {name}
          </span>
        )}
        {size && (
          <span className={styles.size}>
            {(size / 1024 / 1024).toFixed(3)}
            Мб
          </span>
        )}
      </div>
      {!isCopy && (
        <Button
          variant="transparent"
          type="button"
          className={styles.cardDeleteButton}
          onClick={onDelete}
        >
          <BasketIcon />
        </Button>
      )}
    </div>
  );
};

const IndividualCard = ({
  individualData,
  deleteIndividual,
  addDocuments,
  participantData,
  applicationSended,
  isCopy,
  participantItem,
  applicationInfo,
  applicationId,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useForm({});
  const watchedFile = watch("file");
  const watchedDocumentType = watch("documentType");
  const accessToken = localStorage.getItem("accessToken"); // необходим для скачивания файла
  const signatures = participantData?.documentSignatures?.map(
    (item) => item?.document?.uuid
  );

  const signsStatus = participantData?.documentSignatures?.map(
    (object) =>
      object?.document?.uuid ===
        participantData?.legalAuthorityRecord?.uuid && {
        uiid: object?.signature?.person?.uuid,
        status: object?.state,
        presignedSigUrl: object?.presignedSigUrl,
      }
  );

  const [isFileExist, setIsFileExist] = useState(
    watchedFile?.length > 0 ||
      Boolean(participantData?.legalAuthorityRecord?.file)
  );

  const dispatch = useDispatch();

  const { data: documentTypesData } = useQuery(LEGAL_AUTHORITY_RECORD_TYPES);

  const [updateSignatureActive] = useMutation(UPDATE_SIGNATURE_ACTIVE, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: (data) => {},
    onError(err) {
      console.log(err?.message);
    },
  });

  const [updateParticipant] = useMutation(UPDATE_APPLICATION_PARTICIPANT, {
    refetchQueries: [APPLICATION_INFO],
  });

  const [createLegalAuthorityRecord] = useMutation(CREATE_AUTHORITY_RECORD, {
    onCompleted: (data) => {
      updateParticipant({
        variables: {
          participantUuid: participantData?.uuid,
          participantData: {
            legalAuthorityRecordUuid:
              data?.createLegalAuthorityRecord?.legalAuthorityRecord?.uuid,
          },
        },
      });
    },
    onError(err) {
      console.log(err?.message);
    },
  });

  const documentTypes = useMemo(() => {
    const newArray = documentTypesData?.legalAuthorityRecordTypes?.edges?.map(
      (item) => {
        return {
          title: item?.node?.name,
          value: item?.node?.uuid,
        };
      }
    );

    return newArray;
  }, [documentTypesData?.legalAuthorityRecordTypes?.edges]);

  const isReprestntative =
    individualData?.participantType === "representative" || addDocuments;

  useEffect(() => {
    if (
      participantData?.legalAuthorityRecord?.documentType?.uuid &&
      documentTypes?.length > 0
    ) {
      setValue(
        "documentType",
        participantData?.legalAuthorityRecord?.documentType?.uuid
      );
    }
  }, [
    participantData?.legalAuthorityRecord?.documentType?.uuid,
    documentTypes,
    setValue,
  ]);

  useEffect(() => {
    if (watchedFile?.length > 0) {
      setIsFileExist(true);
    }
    if (watchedDocumentType && watchedFile?.length > 0) {
      let participant;
      if (participantData?.uuid) {
        participant = {
          ...participantData,
          isUpdated: true,
          legalAuthorityRecord: {
            file: watchedFile[0],
            type: {
              uuid: watchedDocumentType,
            },
          },
        };
        dispatch(setParticipantUpdated({ uuid: participantData.uuid }));
      } else {
        participant = {
          ...participantData,
          legalAuthorityRecord: {
            file: watchedFile[0],
            type: {
              uuid: watchedDocumentType,
            },
          },
        };
      }

      dispatch(setParticipant(participant));
      createLegalAuthorityRecord({
        variables: {
          file: watchedFile[0],
          typeUuid: watchedDocumentType,
        },
      });
    }
  }, [watchedDocumentType, watchedFile]);

  const [sendDocument] = useMutation(SEND_DOCUMENT, {
    refetchQueries: [APPLICATION_INFO],
    onCompleted: (data) => {},
    onError(err) {
      toast.error(err.message);
    },
  });

  // const [deleteDocumentSignature] = useMutation(DELETE_DOCUMENT_SIGNATURE, {
  //   refetchQueries: [APPLICATION_INFO],
  //   onError(err) {
  //     console.log(err?.message);
  //   },
  // });

  const getSignatures = () => {
    sendDocument({
      variables: {
        documentUuid: participantData?.legalAuthorityRecord?.uuid,
        participantUuid: participantData?.uuid,
      },
    }).then(() => {
      if (applicationInfo && applicationId) {
        applicationInfo({
          variables: {
            id: applicationId,
          },
        });
      }
    });
  };

  const deleteSignatures = () => {
    sendDocument({
      variables: {
        documentUuid: participantData?.legalAuthorityRecord?.uuid,
        participantUuid: participantData?.uuid,
      },
    });
  };

  function isInArray(status) {
    if (
      signsStatus?.some(
        (e) =>
          e.uiid ===
            (participantData?.representative?.uuid ||
              participantData?.physicalPerson?.uuid) && e.status === status
      )
    ) {
      return true;
    }
    return false;
  }

  const downloadExistFiles = (token, url, name) => {
    fetch(url, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Непредвиденная ошибка");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        const title = /[^/]*$/.exec(url)[0];
        a.href = url;
        a.download = name ? name : title;
        a.target = "_blank";
        a.rel = "noreferrer";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Ошибка скачивания:", error);
      });
  };

  return (
    <div className={!isCopy ? styles.infoCard : styles.infoCopyCard}>
      <div
        className={cn(styles.main, {
          [styles.representative]: isReprestntative,
        })}
      >
        <div className={styles.nameContainer}>
          {(isReprestntative &&
            watchedFile?.length > 0 &&
            watchedFile[0]?.name) ||
          (isReprestntative && isFileExist) ? (
            !isCopy ? (
              <Checkbox
                name="sign"
                register={register}
                label={`${individualData?.lastName} ${individualData?.firstName} ${individualData?.patronymic}`}
                isChecked={signatures.includes(
                  participantData?.legalAuthorityRecord?.uuid
                )}
                onChange={() => {
                  signatures.includes(
                    participantData?.legalAuthorityRecord?.uuid
                  )
                    ? deleteSignatures()
                    : getSignatures();
                }}
              />
            ) : (
              <Checkbox
                name="sign"
                register={register}
                label={`${individualData?.lastName} ${individualData?.firstName} ${individualData?.patronymic}`}
                isChecked={
                  participantData?.legalAuthorityRecord?.documentSignature !==
                  null
                }
                onChange={() => {
                  participantData?.legalAuthorityRecord?.documentSignature !==
                  null
                    ? deleteSignatures()
                    : getSignatures();
                }}
              />
            )
          ) : (
            <span className={styles.span}>
              {individualData?.lastName} {individualData?.firstName}{" "}
              {individualData?.patronymic}
            </span>
          )}

          {individualData?.signaturesCurrent?.length !== 0 && !isCopy ? (
            <Select
              name="lowType"
              variant="bordered-green"
              labelClassName={styles.selectSignature}
              register={register}
              isFormSelect={true}
              options={individualData?.signaturesCurrent}
              placeholder={individualData?.signaturesCurrent?.map(
                (item, index) => {
                  return item?.isActive === true && item?.connector;
                }
              )}
              onChange={(e) =>
                updateSignatureActive({
                  variables: {
                    consumerUuid: e.target.value,
                  },
                })
              }
            />
          ) : isCopy ? (
            <div className={styles.signaturesWrapper}>
              {individualData?.signaturesCurrent.map(
                (item) =>
                  item.isActive && (
                    <Input
                      key={item?.id}
                      value={item?.connector}
                      readOnly={true}
                      variant="bordered-green"
                    />
                  )
              )}
            </div>
          ) : (
            <span className={styles.noneAcp}>ЭЦП не выпущена</span>
          )}
        </div>
        {individualData?.isProfileActivated === true && <span>ЛК</span>}
        {isReprestntative && applicationSended && !isCopy && (
          <Button
            variant="transparent"
            type="button"
            className={styles.cardDeleteButton}
            onClick={() =>
              deleteIndividual(
                isReprestntative ? "representative" : "physicalPerson"
              )
            }
          >
            <BasketIcon />
          </Button>
        )}
      </div>

      {isReprestntative && (
        <div className={styles.representativeAdditional}>
          <div className={cn(styles.selectContainer, styles.half)}>
            {!isCopy ? (
              <Select
                name="documentType"
                register={register}
                isFormSelect={true}
                placeholder="Основание полномочий"
                className={styles.select}
                variant="transparent"
                options={documentTypes}
              />
            ) : (
              <abbr
                title={
                  participantItem?.legalAuthorityRecord?.documentType?.name
                }
              >
                {participantItem?.legalAuthorityRecord
                  ? participantItem?.legalAuthorityRecord?.documentType?.name
                      .length < 20
                    ? participantItem?.legalAuthorityRecord?.documentType?.name
                    : participantItem?.legalAuthorityRecord?.documentType?.name.slice(
                        0,
                        20
                      ) + "..."
                  : null}
              </abbr>
            )}
          </div>

          {isFileExist ? (
            <div className={styles.fileAddedContainer}>
              {watchedFile?.length > 0 ? (
                <FileCard
                  name={watchedFile[0]?.name}
                  fileFowDownload={watchedFile[0]}
                  isCopy={isCopy}
                  size={watchedFile[0]?.size}
                  onDelete={() => {
                    setValue("file", null);
                    setIsFileExist(false);
                  }}
                />
              ) : (
                <FileCard
                  url={participantData?.legalAuthorityRecord?.presignedUrl}
                  name={participantData?.legalAuthorityRecord?.file}
                  isCopy={isCopy}
                  onDelete={() => {
                    setValue("file", null);
                    setIsFileExist(false);
                  }}
                />
              )}
              {isReprestntative &&
                participantData?.legalAuthorityRecord?.state === "COMPLETE" && (
                  <span
                    className={styles.noneAcpGreen}
                    onClick={() =>
                      downloadExistFiles(
                        accessToken,
                        participantData?.legalAuthorityRecord?.presignedSigUrl
                      )
                    }
                  >
                    Подписано
                  </span>
                )}
              {isReprestntative &&
                participantData?.legalAuthorityRecord?.state === "REJECTED" && (
                  <span className={styles.noneAcp}>Подписание отклонено</span>
                )}
              {isReprestntative &&
                participantData?.legalAuthorityRecord?.documentSignature && (
                  <span className={styles.noneAcp}>Подпись запрошена</span>
                )}
              {isReprestntative &&
                !participantData?.legalAuthorityRecord?.documentSignature && (
                  <span className={styles.noneAcp}>Подпись не запрошена</span>
                )}
              {isReprestntative && isInArray("ERROR") === true && (
                <span className={styles.noneAcp}>Ошибка подписания</span>
              )}
              {/* {isReprestntative &&
                !signatures.includes(
                  participantData?.legalAuthorityRecord?.uuid
                ) && (
                  <span className={styles.noneAcp}>Подпись не запрошена</span>
                )} */}
            </div>
          ) : (
            <div className={styles.addFileContainer}>
              {!isCopy && (
                <label>
                  <span className={styles.addFile}>Загрузить документ...</span>
                  <input
                    type="file"
                    {...register("file")}
                    placeholder="Загрузить документ..."
                    className={styles.fileInput}
                  />
                </label>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EntityCard = ({ entityData, deleteEntity }) => {
  const { formatDocumentDate } = useFormatDocumentDate();

  const companyName =
    entityData?.node?.fullName || entityData?.legalPerson?.fullName;

  const document =
    entityData?.legalAuthorityRecord ||
    entityData?.representative?.[0]?.legalAuthorityRecord ||
    entityData?.representative?.legalAuthorityRecord;
  const representativePerson =
    entityData?.representative?.physicalPerson ||
    entityData?.representative?.[0]?.physicalPerson ||
    entityData?.representative;

  return (
    <div className={styles.infoCardContainer}>
      <div className={cn(styles.infoCard, styles.entityiInfoCard)}>
        <div className={styles.info}>
          <div className={styles.infoCol}>
            {companyName?.length > 10 ? (
              <abbr title={companyName}>
                <strong>{companyName}</strong>
              </abbr>
            ) : (
              <strong>{companyName}</strong>
            )}
          </div>
          {representativePerson?.id ? (
            <div className={styles.infoCol}>
              <strong>
                {representativePerson?.lastName}{" "}
                {representativePerson?.firstName}{" "}
                {representativePerson?.patronymic}
              </strong>
              {document?.documentType?.uuid ? (
                <span className={styles.description}>
                  {document?.documentType?.name} до{" "}
                  {formatDocumentDate(document?.durationStop)}
                </span>
              ) : (
                <span className={styles.description}>документ не загружен</span>
              )}
            </div>
          ) : (
            <span className={styles.description}>Нет представителей</span>
          )}
        </div>

        {/* <Button
					variant="transparent"
					type="button"
					className={styles.cardDeleteButton}
					onClick={deleteEntity}
				>
					<BasketIcon />
				</Button> */}
      </div>
    </div>
  );
};

export const StandartParticipantCard = ({
  deleteAllCard,
  lawType,
  setParticipantsUuids,
  participantsUuids,
  roles,
  setParticipantType,
  participantItem,
  applicationSended,
  isCopy,
  documents,
  applicationInfo,
  applicationId,
}) => {
  const dispatch = useDispatch();
  const [showEntityForm, setShowEntityForm] = useState(false);
  const [showIndividualForm, setShowIndividualForm] = useState({
    open: false,
    type: "",
  });
  const [selected, setSelected] = useState(false);
  const [updateParticipant] = useMutation(UPDATE_APPLICATION_PARTICIPANT, {
    refetchQueries: [APPLICATION_INFO],
  });

  const [updateParticipantShares] = useMutation(UPDATE_APPLICATION_PARTICIPANT);
  const [uniqRoles, setUniqRoles] = useState([]);

  const setParticipantRoleHanlde = useCallback(
    (role) => {
      const id = participantItem?.id || participantItem?.uuid;

      if (id && role) {
        dispatch(setParticipantRole({ id: id, role: role }));
      }
    },
    [dispatch, participantItem?.id, participantItem?.uuid]
  );

  const setParticipantShareHanlde = useCallback(
    (shareOne, shareTwo) => {
      const id = participantItem?.id || participantItem?.uuid;

      if (id && shareOne && shareTwo) {
        dispatch(
          setParticipantShare({
            id: id,
            shareDenominator: +shareTwo,
            shareNumerator: +shareOne,
          })
        );
      }
    },
    [dispatch, participantItem?.id, participantItem?.uuid]
  );

  const role = useMemo(() => {
    return roles?.find((item) => item?.title === participantItem?.role?.name);
  }, [participantItem?.role, roles]);

  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      share1: participantItem?.shareNumerator,
      share2: participantItem?.shareDenominator,
    },
  });

  const watchedRole = watch("role");

  // const uniqRoles = () => {
  //   const newRoles = [];
  //   if(roles.length > 0){

  //   }
  //   roles?.forEach((role) => {
  //     if (!newRoles.some((item) => item.title === role.title)) {
  //       newRoles.push(role);
  //     }
  //   });
  //   return newRoles;
  // };

  useEffect(() => {
    if (roles && roles.length > 0) {
      const newRoles = [];
      roles?.forEach((role) => {
        if (!newRoles.some((item) => item?.title === role?.title)) {
          newRoles.push(role);
        }
      });
      setUniqRoles(newRoles)
    }
  }, [roles]);

  useEffect(() => {
    let array = [];

    if (
      participantItem?.participantType?.toLowerCase() === "physical_person" &&
      participantItem?.physicalPerson?.uuid
    ) {
      array = [
        participantItem?.physicalPerson?.uuid,
        participantItem?.representative?.uuid,
      ];
    } else if (participantItem?.node?.uuid) {
      array = [
        participantItem?.node?.uuid,
        participantItem?.representative?.uuid,
      ];
    }

    if (array.length > 0 && !isCopy) {
      const newParticipantsUuids = participantsUuids.concat(array);
      const result = [...new Set(newParticipantsUuids)];
      setParticipantsUuids(result);
    }

  
  }, [participantItem]);

  const watchedShare1 = watch("share1");
  const watchedShare2 = watch("share2");

  useEffect(() => {
    setParticipantShareHanlde(watchedShare1, watchedShare2);
    if (watchedShare1 && watchedShare2 && participantItem?.uuid) {
      updateParticipantShares({
        variables: {
          participantUuid: participantItem?.uuid,
          participantData: {
            role: watchedRole || role?.value,
            physicalPersonUuid:
              participantItem?.physicalPerson?.uuid?.toString() || null,
            shareDenominator: parseInt(watchedShare2),
            shareNumerator: parseInt(watchedShare1),
          },
        },
      });
    }
  }, [watchedShare1, watchedShare2]);

  const deleteIndividual = (type) => {
    if (participantItem?.uuid) {
      if (type === "representative") {
        updateParticipant({
          variables: {
            participantUuid: participantItem?.uuid,
            participantData: {
              role: watchedRole || role?.value || null,
              participantType:
                participantItem?.participantType?.toLowerCase() || "",
              physicalPersonUuid:
                participantItem?.physicalPerson?.uuid?.toString() || null,
              shareDenominator: participantItem?.shareDenominator,
              shareNumerator: participantItem?.shareNumerator,
              representativeUuid: null,
              legalAuthorityRecordUuid: null,
            },
          },
        });
      }
    } else {
      if (type === "representative") {
        const newParticipant = {
          ...participantItem,
          representative: null,
        };

        dispatch(setParticipant(newParticipant));
      }
    }
  };

  const deleteEntity = () => {
    if (participantItem?.uuid) {
      // Для юр лица сейчас не удаляет, надо разщбиратьтся с беком, почему не работает
      // updateParticipant({
      // 	variables: {
      // 		participantUuid: participantItem?.uuid,
      // 		participantData: {
      // 			role: watchedRole || role?.value || null,
      // 			participantType:
      // 				participantItem?.participantType?.toLowerCase() ||
      // 				"",
      // 			shareDenominator: participantItem?.shareDenominator,
      // 			shareNumerator: participantItem?.shareNumerator,
      // 			legalPersonUuid:  null,
      // 			representativeUuid: null,
      // 			legalAuthorityRecordUuid: null,
      // 		},
      // 	},
      // });
    } else {
      const newParticipant = {
        id: participantItem?.id,
      };

      dispatch(deleteInnerParticipant(newParticipant));
    }
  };

  const closeIndividualForm = () => {
    setShowIndividualForm({ open: false, type: "" });
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerInner}>
          <div className={styles.selectContainer}>
            {!isCopy && uniqRoles.length > 0 ? (
              <Select
                name="role"
                register={register}
                isFormSelect={true}
                className={styles.select}
                placeholder={
                  participantItem?.role ? role?.title : "Выберите роль"
                }
                variant="transparent"
                options={uniqRoles}
                onChange={(e) => {
                  setParticipantRoleHanlde(e.target.value);
                  setSelected(true);
                }}
              />
            ) : (
              <Input
                value={participantItem?.role?.name}
                readOnly={true}
                variant="transparent"
                className={styles.participantInput}
              />
            )}
            {role == null && selected === false && !isCopy && (
              <p className={styles.error}>Выберите роль</p>
            )}
          </div>

          {lawType === "ac526061-4265-4fb8-8772-4b4e533107de" && (
            <div className={styles.shareContainer}>
              <span>Доля</span>
              <div className={styles.inputs}>
                <Input
                  name="share1"
                  variant="bordered-green"
                  register={register}
                  isFormField={true}
                />
                <span> : </span>
                <Input
                  name="share2"
                  variant="bordered-green"
                  register={register}
                  isFormField={true}
                />
              </div>
            </div>
          )}

          {participantItem?.physicalPerson?.firstName && (
            <div
              className={
                !isCopy
                  ? styles.infoCardContainer
                  : styles.infoCardCopyContainer
              }
            >
              <IndividualCard
                participantData={participantItem}
                individualData={participantItem?.physicalPerson}
                deleteIndividual={deleteIndividual}
                applicationSended={applicationSended}
                isCopy={isCopy}
                participantItem={participantItem}
                applicationInfo={applicationInfo}
                applicationId={applicationId}
              />
              {participantItem?.representative ? (
                <IndividualCard
                  participantData={participantItem}
                  applicationInfo={applicationInfo}
                  applicationId={applicationId}
                  isCopy={isCopy}
                  individualData={
                    participantItem?.representative?.[0]
                      ? participantItem?.representative?.[0]
                      : participantItem?.representative
                  }
                  addDocuments={true}
                  deleteIndividual={deleteIndividual}
                  applicationSended={applicationSended}
                  participantItem={participantItem}
                />
              ) : (
                !isCopy && (
                  <Button
                    variant="transparent"
                    className={styles.addBtn}
                    type="button"
                    onClick={() => {
                      setShowIndividualForm({
                        open: true,
                        type: "representative",
                      });
                      setParticipantType("representative");
                    }}
                  >
                    <span>+&nbsp;Представитель</span>
                  </Button>
                )
              )}
            </div>
          )}

          {(participantItem?.node?.id ||
            participantItem.participantType === "LEGAL_PERSON") && (
            <EntityCard
              entityData={participantItem}
              deleteEntity={deleteEntity}
            />
          )}

          {!participantItem?.physicalPerson?.firstName &&
            !participantItem?.firstName &&
            !participantItem?.node?.id &&
            !participantItem?.uuid && (
              <>
                <Button
                  variant="transparent"
                  className={styles.addBtn}
                  type="button"
                  onClick={() => {
                    setShowIndividualForm({
                      open: true,
                      type: "individual",
                    });
                    setParticipantType("physical_person");
                  }}
                >
                  <span>+&nbsp;Физлицо</span>
                </Button>
                <Button
                  variant="transparent"
                  className={styles.addBtn}
                  type="button"
                  onClick={() => {
                    setShowEntityForm(true);
                    setParticipantType("LEGAL_PERSON");
                  }}
                >
                  <span>+&nbsp;Юрлицо</span>
                </Button>
              </>
            )}
        </div>
        {!isCopy && (
          <div className={styles.deleteBtn}>
            {applicationSended && (
              <Button
                variant="transparent"
                type="button"
                onClick={() => deleteAllCard(participantItem)}
              >
                <BasketIcon />
              </Button>
            )}
          </div>
        )}
      </div>

      <ModalWindow
        show={showIndividualForm.open}
        setShowModal={closeIndividualForm}
        dialogClassName={styles.modal}
        className={styles.modalInner}
      >
        <IndividualBaseFinder
          individualData={participantItem}
          setShow={closeIndividualForm}
          participantType={showIndividualForm.type}
          setParticipantType={setParticipantType}
          role={watchedRole || role?.value}
          cardId={participantItem?.id}
        />
      </ModalWindow>

      <ModalWindow
        show={showEntityForm}
        setShowModal={() => setShowEntityForm(false)}
        dialogClassName={styles.modal}
        className={styles.modalInner}
      >
        <EntityBaseFinder
          cardId={participantItem}
          setShow={setShowEntityForm}
          setParticipantType={setParticipantType}
          role={watchedRole || role?.value}
        />
      </ModalWindow>
    </>
  );
};
