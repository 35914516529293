import { Button } from "../../../Button";
import { InfoList } from "../../../InfoList/ui/InfoList";
import styles from "./ExtractsList.module.scss";
import cn from "classnames";
import { useState } from "react";
import { Pagination } from "../../../Pagination/ui/Pagination";

export const ExtractsList = ({ extractsData }) => {
  const [page, setPage] = useState(1);
  const totalResults = 10;
  const indexOfLastPage = page * totalResults;
  const indexOfFirstPage = indexOfLastPage - totalResults;

  console.log(extractsData)

  return (
    <ul className={styles.extractsList}>
      {extractsData
        .slice(indexOfFirstPage, indexOfLastPage)
        .map((extract, index) => (
          <InfoList key={index}>
            <div className={styles.extractsInner}>
              <div className={styles.extractsInfoBox}>
                <span className={styles.date}>{extract.date}</span>
                <span>{extract.cadNumber}</span>
                <div className={styles.extractTypes}>
                  {extract.types.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </div>
              </div>

              <div>
                <span
                  className={cn({
                    [styles.grayStatus]:
                      extract.status === "Запрос отправляется" ||
                      extract.status === "Запрос отправлен",
                  })}
                >
                  {extract.status !== "Отказ в выписке" ? (
                    extract.status
                  ) : (
                    <span>
                      <span className={styles.rejection}>Отказ</span> в выписке
                    </span>
                  )}
                </span>
                {extract.status === "Получены выписки" && (
                  <Button
                    variant="outline-green"
                    className={styles.extractOpen}
                  >
                    Открыть
                  </Button>
                )}
              </div>
            </div>
          </InfoList>
        ))}

      {extractsData.length > 10 && (
        <Pagination
          data={extractsData}
          page={page}
          setPage={setPage}
          totalResults={totalResults}
        />
      )}
    </ul>
  );
};
