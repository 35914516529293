import ccpa from "crypto-pro-cadesplugin";
import { Button } from "../../Button";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./CryptoTokenMassive.module.scss";

export const CryptoTokenMassive = ({
  documents,
  certificateInfo,
  certificateNumber,
  currentCertificate
}) => {
  const certsApi = ccpa();
  const { token } = useParams();
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [isSigned, setIsSigned] = useState(false);
  const [error, setError] = useState("");

  const addClientDocuments = async (body) => {
    try {
      const response = await fetch(
        `https://api.unireg.redfoxlab.ru/signing/${token}/documents/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Не удалось отправить документы");
      }

      const data = await response.json();

      setIsSigned(true);

      return data;
    } catch (error) {
      setError(error.message);
    }
  };

  // const signatureDocuments = async () => {
  //   if (documents.length > 0) {
  //     await documents.forEach((document) => {
  //       certsApi.then((data) =>
  //         data
  //           .signBase64(certificateInfo.Thumbprint, document?.file)
  //           .then((data) => {
  //             setConvertedFiles([]);
  //             setConvertedFiles((prev) => [
  //               ...prev,
  //               { id: document?.signature_document_id, file: data },
  //             ]);
  //           })
  //       );
  //     });
  //   }
  // };

  const signatureDoc = async(doc, thumbprint) => {
    const certsApi = await ccpa();
    const sign = await certsApi?.signBase64(thumbprint, doc?.file);
    setConvertedFiles(prev => [...prev, {
      id: doc?.signature_document_id, file: sign, document_uuid: doc?.document_uuid
    }])
  }

  const signatureDocuments = async() => {
    await documents.forEach(document => {
      signatureDoc(document, certificateInfo?.Thumbprint)
    })
  }


  // const signatureDocuments = async () => {
  //   if (documents.length > 0) {
  //     const signPromises = documents.map(async (document) => {
  //       const data = await certsApi; // Ждем, пока certsApi выполнится
  //       const signedData = await data.signBase64(certificateInfo.Thumbprint, document?.file);

  //       // Обновляем состояние с подписанными файлами
  //       setConvertedFiles((prev) => [
  //         ...prev,
  //         { id: document?.signature_document_id, file: signedData, document_uuid: document?.document_uuid },
  //       ]);
  //     });

  //     // Ждем завершения всех подписаний
  //     await Promise.all(signPromises);
  //   }

  // };
  // const getCertificate = async () => {
  //   try {
  //     let cadesplugin = window.cadesplugin;
  //     let store = await cadesplugin.CreateObjectAsync("CAdESCOM.Store");

  //     await store.Open(
  //       cadesplugin.CAPICOM_CURRENT_USER_STORE,
  //       cadesplugin.CAPICOM_MY_STORE,
  //       cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
  //     );
  //     let certs = await store.Certificates;
  //     let certCount = await certs.Count;

  //     if (certCount === 0) {
  //       throw new Error("Не найдено подходящих сертификатов");
  //     }

  //     let certificate = await certs.Item(certificateNumber); // Используем выбраный сертификат в списке
  //     await store.Close();
  //     return certificate;
  //   } catch (error) {
  //     throw new Error("Ошибка получения сертификата: " + error.message);
  //   }
  // };

  // function base64Encode(data) {
  //   return btoa(unescape(encodeURIComponent(data)));
  // }

  // const convertFileToBase64 = async (file) => {

  //   const fileReader = new FileReader();

  //   return new Promise((resolve, reject) => {
  //     fileReader.onload = (e) => {
  //       const header = ";base64,";
  //       const base64Data = e.target.result;
  //       const base64File = base64Data.substr(base64Data.indexOf(header) + header.length);
  //       resolve(base64File)// Возвращаем base64 как результат
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error); // Обрабатываем ошибку
  //     };

  //     fileReader.readAsDataURL(file); // Считываем blob как Data URL
  //   });
  // };

  // const handleFileConversion = async (file) => {
  //   try {
  //     const base64File = await convertFileToBase64(file);
  //     return base64File;
  //   } catch (error) {
  //     console.error("Error converting file:", error);
  //   }
  // };

  


  // async function createSignature(documentContent, index) {
  //   try {
  //     let cadesplugin = window.cadesplugin;

  //     // Кодируем контент в Base64
  //     let encodedContent = base64Encode(documentContent);

  //     let signedData = await cadesplugin.CreateObjectAsync(
  //       "CAdESCOM.CadesSignedData"
  //     );
  //     await signedData.propset_Content(encodedContent); // Передаем закодированные данные

  //     let signer = await cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
  //     let certificate = await getCertificate();

  //     await signer.propset_Certificate(certificate);


  //     console.log(signer, 'signer')

  //     let signature = await signedData.SignCades(
  //       signer,
  //       cadesplugin.CADESCOM_CADES_BES
  //     );
  //     return signature;
  //   } catch (error) {
  //     throw new Error("Не удалось создать подпись: " + error.message);
  //   }
  // }

  // async function signatureDocuments() {
  //   await documents.forEach((doc) => {
  //     try {
  //       let signature = createSignature(doc);
  //       setConvertedFiles((prev) => [
  //         ...prev,
  //         {
  //           id: doc?.signature_document_id,
  //           file: signature,
  //           document_uuid: doc?.document_uuid,
  //         },
  //       ]);
  //     } catch (error) {
  //       console.error("Ошибка при подписании документа:", error);
  //     }
  //   });

  //   if (
  //     convertedFiles.length > 0 &&
  //     convertedFiles.length === documents.length
  //   ) {
  //     addClientDocuments(convertedFiles);
  //   }
  // }

  useEffect(() => {
    if (
      convertedFiles.length > 0 &&
      convertedFiles.length === documents.length
    ) {
      addClientDocuments(convertedFiles);
    }
  }, [documents, convertedFiles]);

  // console.log(certificateInfo, 'info');

  return (
    <>
      {!isSigned ? (
        <Button variant="green" onClick={signatureDocuments}>
          Подписать все
        </Button>
      ) : error !== "" ? <span>{error}</span> : (
        <span className={styles.signed}>Документы успешно подписаны</span>
      )}
      
    </>
  );
};
